// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/users/store'

import contacts from '@src/views/apps/contact/store'
import ticketStatus from '@src/views/apps/ticket-status/store'
import ticketCategory from '@src/views/apps/ticket-category/store'
import interruptionType from '@src/views/apps/interruption-type/store'
import dissatisfactionType from '@src/views/apps/dissatisfaction-type/store'
import quickMessage from '@src/views/apps/quick-message/store'
import badWord from '@src/views/apps/bad-word/store'
import alertWord from '@src/views/apps/alert-word/store'
import departments from '@src/views/apps/department/store'
import webhook from '@src/views/apps/webhook/store'
import server from '@src/views/apps/server/store'
import campaign from '@src/views/apps/campaign/store'
import client from '@src/views/apps/client/store'
import group from '@src/views/apps/group/store'
import company from '@src/views/apps/company/store'
import page from '@src/views/apps/page/store'
import extraInput from '@src/views/apps/extra-input/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import ticket from '@src/views/apps/ticket/store'
import calendar from '@src/views/apps/calendar/store'
import roles from '@src/views/apps/roles-permissions/store'
import medias from '@src/views/apps/social-media/store'
import tag from '@src/views/apps/tag/store'

const rootReducer = {
  auth,
  ticket,
  todo,
  chat,
  contacts,
  departments,
  ticketCategory,
  ticketStatus,
  interruptionType,
  dissatisfactionType,
  quickMessage,
  badWord,
  alertWord,
  webhook,
  campaign,
  client,
  server,
  group,
  company,
  page,
  extraInput,
  email,
  users,
  kanban,
  navbar,
  layout,
  calendar,
  roles,
  medias,
  tag
}

export default rootReducer
