// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await api.get('/user')

  return response.data.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await api.get('/user', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getUsers = createAsyncThunk('appUsers/getUsers', async id => {
  const response = await api.get(`/user/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appUsers/getTicketCount', async id => {
  const response = await api.get(`/user/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appUsers/getMessageCount', async id => {
  const response = await api.get(`/user/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appUsers/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appUsers/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appUsers/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appUsers/setError', async (data) => {
  return data
})

export const getUserCount = createAsyncThunk('appContacts/getUserCount', async () => {
  const response = await api.get(`/user/count`)
  return response.data
})

export const addUsers = createAsyncThunk('appUsers/addUsers', async (users, { dispatch, getState }) => {
  try {
    const response = await api.post('/user', users)
    dispatch(getData(getState().users.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateUsers = createAsyncThunk('appUsers/updateUsers', async ({ id, users }, { dispatch }) => {
  dispatch(setSaving(true))
  try {
    const response = await api.put(`/user/${id}`, users)
    dispatch(getUsers(parseInt(id)))
    dispatch(setError({}))
    dispatch(toggleEditModal())
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteUsers = createAsyncThunk('appUsers/deleteUsers', async (id, { dispatch, getState }) => {
  await api.delete(`/user/${id}`)
  await dispatch(getData(getState().user.params))
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUsers: null,
    errors: {},
    saving: false,
    active: 0,
    totalUsers: 0,
    selectedUser: {},
    onlineUsers: 0,
    offlineUsers: 0,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const user = state.data.find(u => u.id === id)

        if (user) state.selectedUser = user
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.selectedUsers = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedUsers = {
          ...state.selectedUsers, ticketsCount: action.payload
        }
      })
      .addCase(getUserCount.fulfilled, (state, action) => {
        state.active = action.payload.active
        state.totalUsers = action.payload.total
        state.onlineUsers = action.payload.online
        state.offlineUsers = action.payload.offline
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedUsers = {
          ...state.selectedUsers, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addUsers.pending, (state) => {
        state.saving = true
      })
      .addCase(addUsers.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addUsers.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateUsers.pending, (state) => {
        state.saving = true
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        state.saving = false
        state.selectedUsers = action.payload.data
      })
      .addCase(updateUsers.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appUsersSlice.reducer
