// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appGroup/getAllData', async () => {
  const response = await api.get('/group')

  return response.data.data
})

export const getData = createAsyncThunk('appGroup/getData', async params => {
  const response = await api.get('/group', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getGroup = createAsyncThunk('appGroup/getGroup', async id => {
  const response = await api.get(`/group/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appGroup/getTicketCount', async id => {
  const response = await api.get(`/group/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appGroup/getMessageCount', async id => {
  const response = await api.get(`/group/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appGroup/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appAlertWord/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appAlertWord/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})


export const setError = createAsyncThunk('appGroup/setError', async (data) => {
  return data
})

export const addGroup = createAsyncThunk('appGroup/addGroup', async (group, { dispatch, getState }) => {
  try {
    const response = await api.post('/group', group)
    dispatch(getData(getState().group.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateGroup = createAsyncThunk('appGroup/updateGroup', async ({ id, group }, { dispatch }) => {
  try {
    const response = await api.put(`/group/${id}`, group)
    dispatch(getGroup(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteGroup = createAsyncThunk('appGroup/deleteGroup', async (id, { dispatch, getState }) => {
  await api.delete(`/group/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appGroupSlice = createSlice({
  name: 'appGroup',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedGroup: {},
    errors: {},
    saving: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const group = state.data.find(u => u.id === id)

        if (group) state.selectedGroup = group
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        state.selectedGroup = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedGroup = {
          ...state.selectedGroup, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedGroup = {
          ...state.selectedGroup, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addGroup.pending, (state) => {
        state.saving = true
      })
      .addCase(addGroup.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addGroup.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateGroup.pending, (state) => {
        state.saving = true
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.saving = false
        state.selectedGroup = action.payload.data
      })
      .addCase(updateGroup.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appGroupSlice.reducer
