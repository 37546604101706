
import {
    UncontrolledTooltip
} from 'reactstrap'

import {
    FaRegCommentDots
} from "react-icons/fa"

import { User } from 'react-feather'

import { useSelector } from 'react-redux'

export default function InternalChatMenu({ setInternalChatActive }) {

    const onlineUsers = useSelector(state => state.users.onlineUsers)

    return (

        <div >
            < UncontrolledTooltip target='internal-chat-icon' >
                Chat Interno
            </UncontrolledTooltip >
            <User onClick={setInternalChatActive}  size={22} id='internal-chat-icon' />
            <span style={{position: 'relative', right: '5px'}}> {onlineUsers} </span>
        </div >
    )
}