// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import api from "../../../../../src/utility/api"

export const toggleLoading = createAsyncThunk('appTodo/toggleLoading', async (_, { getState }) => {
  return !getState().todo.loading
})

export const toggleSaving = createAsyncThunk('appTodo/toggleSaving', async (_, { getState }) => {
  return !getState().todo.saving
})

export const getTasks = createAsyncThunk('appTodo/getTasks', async (params, { dispatch }) => {
  dispatch(toggleLoading())
  const response = await api.get('/task', { params })
  dispatch(toggleLoading())
  const data = response.data.data
  return {
    params,
    data
  }
})

export const addTask = createAsyncThunk('appTodo/addTask', async (task, { dispatch, getState }) => {
  dispatch(toggleSaving())
  const response = await api.post('/task', { ...task })
  dispatch(toggleSaving())
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const updateTask = createAsyncThunk('appTodo/updateTask', async (task, { dispatch, getState }) => {
  dispatch(toggleSaving())
  const response = await api.put(`/task/${task.id}`, { ...task })
  dispatch(toggleSaving())
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const deleteTask = createAsyncThunk('appTodo/deleteTask', async (taskId, { dispatch, getState }) => {
  const response = await axios.delete('/apps/todo/delete-task', { taskId })
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const appTodoSlice = createSlice({
  name: 'appTodo',
  initialState: {
    tasks: [],
    selectedTask: {},
    loading: false,
    saving: false,
    params: {
      q: '',
      sort: '',
      tag: '',
      is_completed: 0,
      is_deleted: 0,
      is_important: 0
    }
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.tasks = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data.map(t => ({
        ...t,
        start_at: new Date(t.start_at),
        end_at: t.end_at ? new Date(t.end_at) : t.end_at
      }))
      state.params = { ...state.params, ...action.payload.params }
    })
      .addCase(toggleLoading.fulfilled, (state, action) => {
        state.loading = action.payload
      })
      .addCase(toggleSaving.fulfilled, (state, action) => {
        state.saving = action.payload
      })
  }
})

export const { reOrderTasks, selectTask } = appTodoSlice.actions

export default appTodoSlice.reducer
