
// ** Reactstrap Imports
import {
    Row,
    Form,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    Spinner
} from 'reactstrap'

import qrCode from '../../../../../assets/images/elements/qrCode.png'

export default function FormOfficial() {
    return (

        <Form >
            <Row>
                <Col md='6' sm='12' className='mb-1'>
                    <div className='mb-1'>
                        <Label>
                            Nome do Assistente Virtual
                 </Label>
                        <Input size='sm' />
                    </div>
                    <div className='form-check form-check-inline mb-1'>
                        <Label className='form-check-label'>
                            Resposta Automática
                </Label>
                        <Input type='checkbox' />

                    </div>
                    <div className='mb-1'>
                        <Label>
                            Texto para mensagem automática:
                 </Label>
                        <Input type='textarea' size='sm' />
                    </div>
                    <div className='d-flex aligm-itens-center flex-column'>

                        <div className='form-check form-check-inline '>
                            <Label className='form-check-label'>
                                Resposta Automática
                            </Label>
                            <Input type='checkbox' />
                        </div>

                        <div className='form-check form-check-inline'>
                            <Label className='form-check-label'>
                                Envio em Massa?
                            </Label>
                            <Input type='checkbox' />

                        </div>

                        <div className='form-check form-check-inline'>
                            <Label className='form-check-label'>
                                Atendimento Automatizado?
                            </Label>
                            <Input type='checkbox' />
                        </div>
                        <div className='form-check form-check-inline'>
                            <Label className='form-check-label'>
                                Pode responder?
                            </Label>
                            <Input type='checkbox' />
                        </div>
                        <div className='form-check form-check-inline'>
                            <Label className='form-check-label'>
                                Enviar e-mail quando a conta perder autentificação?
                            </Label>
                            <Input type='checkbox' />
                        </div>
                    </div>
                </Col>
                <Col md='6' sm='12' className='mb-1'>
                    <div className='d-flex justify-center'>
                        <img style={{ width: '225px' }} src={qrCode} />
                    </div>

                </Col>

            </Row>

        </Form>

    )

}