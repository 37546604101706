
import '../../../../style.css'

import {
    UncontrolledTooltip
} from 'reactstrap'

import { useState } from 'react'

import { UserPlus } from 'react-feather'
import NewConversationModal from '../../../../views/apps/chat/NewConversationModal'


export default function NewConversation() {

    const [newConversationOpen, setNewConversationOpen] = useState(false)
    const toggleNewConversation = () => setNewConversationOpen(!newConversationOpen)

    return (
        <div >
            <UncontrolledTooltip target='new-conversation-icon'>
                Nova Conversa
            </UncontrolledTooltip>
            <UserPlus size={22}id='new-conversation-icon' onClick={toggleNewConversation} />

            <NewConversationModal open={newConversationOpen} toggleNewConversationModal={toggleNewConversation} />
        </div>
    )
}