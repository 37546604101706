// ** Icons Import
import { Heart } from 'react-feather'
import vipphone from "../../../../../src/assets/images/icons/viphone.png"
const Footer = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-start d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a href='https://1.envato.market/pixinvent_portfolio' target='_blank' rel='noopener noreferrer'>
          Monitchat
        </a>
        <span className='d-none d-sm-inline-block'>, Todos os direitos reservados</span>
      </span>
      <span className='float-md-end d-none d-md-block'>
        Powered by Vipphone Softwares
        <img src={vipphone} style={{ marginLeft: 5, height: '18px', width: '18px' }} />
      </span>
    </p>
  )
}

export default Footer
