import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import '../../../userBar.css'
import { getInternalChats } from './store'
import searchIcon from '../../../assets/images/icons/searchIcon.svg'

const HeaderUserBar = () => {
    const [query, setQuery] = useState('')
    const [timer, setTimer] = useState(null)
    const [filteredChat, setFilteredChat] = useState([])
    const dispatch = useDispatch()
    const store = useSelector(state => ({ ...state.chat }))
    const { internal: chats } = store
    // ** Handles Filter
    const handleFilter = e => {
        setTimer(clearTimeout(timer))
        setQuery(e.target.value)
        const searchFilterFunction = participant => participant.fullName?.toLowerCase().includes(e.target.value.toLowerCase()) || participant.name?.toLowerCase().includes(e.target.value.toLowerCase())

        const filteredChatsArr = chats.filter(searchFilterFunction)
        setFilteredChat([...filteredChatsArr])

        setTimer(
            setTimeout(async () => {
                const result = await dispatch(
                    getInternalChats({
                        query: e.target.value,
                        skip: 0,
                        perPage: 30
                    })
                )

                setFilteredChat([...result.payload])
            }, 500)
        )
    }

    return (
        <div className='header-bar'>
            <h2>
                <span id='title-header'>
                    Chat Interno
                    </span>
            </h2>
            <div className='search-user-content'>
                <div className='background-icon'>
                    <img src={searchIcon} id='icon-search' />
                </div>
                <input id='input-search-user'

                    value={query}
                    onChange={handleFilter}
                    type='text' placeholder='Pesquisar Usuário'></input>
            </div>
        </div>
    )
}

export default HeaderUserBar