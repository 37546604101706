// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appServer/getAllData', async () => {
  const response = await api.get('/server')

  return response.data.data
})

export const getData = createAsyncThunk('appServer/getData', async params => {
  const response = await api.get('/server', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getServer = createAsyncThunk('appServer/getServer', async id => {
  const response = await api.get(`/server/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appServer/getTicketCount', async id => {
  const response = await api.get(`/server/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appServer/getMessageCount', async id => {
  const response = await api.get(`/server/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appServer/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appServer/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appServer/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})


export const setError = createAsyncThunk('appServer/setError', async (data) => {
  return data
})

export const addServer = createAsyncThunk('appServer/addServer', async (server, { dispatch, getState }) => {
  try {
    const response = await api.post('/server', server)
    dispatch(getData(getState().server.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateServer = createAsyncThunk('appServer/updateServer', async ({ id, server }, { dispatch }) => {
  try {
    const response = await api.put(`/server/${id}`, server)
    dispatch(getServer(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteServer = createAsyncThunk('appServer/deleteServer', async (id, { dispatch, getState }) => {
  await api.delete(`/server/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appServerSlice = createSlice({
  name: 'appServer',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedServer: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const selectedServer = state.data.find(u => u.id === id)

        if (selectedServer) state.selectedServer = selectedServer
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getServer.fulfilled, (state, action) => {
        state.selectedServer = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedServer = {
          ...state.selectedServer, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedServer = {
          ...state.selectedServer, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addServer.pending, (state) => {
        state.saving = true
      })
      .addCase(addServer.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addServer.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateServer.pending, (state) => {
        state.saving = true
      })
      .addCase(updateServer.fulfilled, (state, action) => {
        state.saving = false
        state.selectedServer = action.payload.data
      })
      .addCase(updateServer.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appServerSlice.reducer
