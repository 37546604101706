// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appBadWord/getAllData', async () => {
  const response = await api.get('/bad-word')

  return response.data.data
})

export const getData = createAsyncThunk('appBadWord/getData', async params => {
  const response = await api.get('/bad-word', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getBadWord = createAsyncThunk('appBadWord/getBadWord', async id => {
  const response = await api.get(`/bad-word/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appBadWord/getTicketCount', async id => {
  const response = await api.get(`/bad-word/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appBadWord/getMessageCount', async id => {
  const response = await api.get(`/bad-word/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appBadWord/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appBadWord/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appBadWord/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appBadWord/setError', async (data) => {
  return data
})

export const addBadWord = createAsyncThunk('appBadWord/addBadWord', async (badWord, { dispatch, getState }) => {
  try {
    const response = await api.post('/bad-word', badWord)
    dispatch(getData(getState().badWord.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateBadWord = createAsyncThunk('appBadWord/updateBadWord', async ({ id, badWord }, { dispatch }) => {
  try {
    const response = await api.put(`/bad-word/${id}`, badWord)
    dispatch(getBadWord(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteBadWord = createAsyncThunk('appBadWord/deleteBadWord', async (id, { dispatch, getState }) => {
  await api.delete(`/bad-word/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appBadWordSlice = createSlice({
  name: 'appBadWord',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedBadWord: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const selectedBadWord = state.data.find(u => u.id === id)

        if (selectedBadWord) state.selectedBadWord = selectedBadWord
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getBadWord.fulfilled, (state, action) => {
        state.selectedBadWord = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedBadWord = {
          ...state.selectedBadWord, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedBadWord = {
          ...state.selectedBadWord, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addBadWord.pending, (state) => {
        state.saving = true
      })
      .addCase(addBadWord.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addBadWord.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateBadWord.pending, (state) => {
        state.saving = true
      })
      .addCase(updateBadWord.fulfilled, (state, action) => {
        state.saving = false
        state.selectedBadWord = action.payload.data
      })
      .addCase(updateBadWord.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appBadWordSlice.reducer
