import { useDispatch } from 'react-redux'
import { openInternalChatWindow } from './store'
import '../../../userBar.css'


export default function InternalChatItem({ chat }) {
    const dispatch = useDispatch()

    return (
        <div key={`internal-chat-${chat.id}`} onClick={() => dispatch(openInternalChatWindow(chat.id))} className='user-info'>
            <div className='content-icon-user'>
                <img id='icon-user' src={chat.participants[0].avatar} />
            </div>
            <div className='content-text'>
                <div>
                    <span id='name-user'> {chat.name} </span>
                </div>
                <div className='content-last-msg'>
                    <span id='last-msg'> {chat.last_message} </span>
                </div>
            </div>
        </div>
    )
}                