// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appTicketCategory/getAllData', async () => {
  const response = await api.get('/ticket-category')

  return response.data.data
})

export const getData = createAsyncThunk('appTicketCategory/getData', async params => {
  const response = await api.get('/ticket-category', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getTicketCategory = createAsyncThunk('appTicketCategory/getTicketCategory', async id => {
  const response = await api.get(`/ticket-category/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appTicketCategory/getTicketCount', async id => {
  const response = await api.get(`/ticket-category/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appTicketCategory/getMessageCount', async id => {
  const response = await api.get(`/ticket-category/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appTicketCategory/setSaving', async (saving) => {
  return saving
})

export const setError = createAsyncThunk('appTicketCategory/setError', async (data) => {
  return data
})

export const addTicketCategory = createAsyncThunk('appTicketCategory/addTicketCategory', async (ticketCategory, { dispatch, getState }) => {
  try {
    const response = await api.post('/ticket-category', ticketCategory)
    dispatch(getData(getState().ticketCategory.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateTicketCategory = createAsyncThunk('appTicketCategory/updateTicketCategory', async ({ id, ticketCategory }, { dispatch }) => {
  try {
    const response = await api.put(`/ticket-category/${id}`, ticketCategory)
    dispatch(getTicketCategory(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteTicketCategory = createAsyncThunk('appTicketCategory/deleteTicketCategory', async (id, { dispatch, getState }) => {
  await api.delete(`/ticket-category/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appTicketCategorySlice = createSlice({
  name: 'appTicketCategory',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTicketCategory: null,
    errors: {},
    saving: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTicketCategory.fulfilled, (state, action) => {
        state.selectedTicketCategory = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedTicketCategory = {
          ...state.selectedTicketCategory, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedTicketCategory = {
          ...state.selectedTicketCategory, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addTicketCategory.pending, (state) => {
        state.saving = true
      })
      .addCase(addTicketCategory.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addTicketCategory.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateTicketCategory.pending, (state) => {
        state.saving = true
      })
      .addCase(updateTicketCategory.fulfilled, (state, action) => {
        state.saving = false
        state.selectedTicketCategory = action.payload.data
      })
      .addCase(updateTicketCategory.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appTicketCategorySlice.reducer
