// ** Auth Endpoints
export default {
  //loginEndpoint: '/jwt/login',
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  refreshEndpoint: '/refresh-token',
  logoutEndpoint: '/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  apiBaseUrl: process.env.REACT_APP_API_V2_ROOT_URL,
  authUrl: process.env.REACT_APP_AUTH_URL
}
