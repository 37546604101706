
import { useState } from 'react'
// ** Reactstrap Imports
import {
    Row,
    Form,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    Spinner
} from 'reactstrap'

import FormOfficial from './EditForms/EditFormOfficial'
import UnofficialForm from './EditForms/EditUnofficialForm'


export default function EditSocialMediaModal({ open, toggleEditSocialMedia }) {

    const [formOfficial, setFormOfficial] = useState(false)
    const toggleForm = () => setFormOfficial(!formOfficial)

    return (

        <Modal className='modal-dialog-centered modal-lg' isOpen={open} >
            <ModalHeader toggle={toggleEditSocialMedia}>
                Edição do 5527997444460
                <Button style={{ marginLeft: '15px' }} onClick={toggleForm}>
                    Trocar
                </Button>
            </ModalHeader>
            <ModalBody>

                {formOfficial ? <FormOfficial /> : <UnofficialForm />}
            </ModalBody>
            <ModalFooter>
                <Button color='secundary'>Cancelar</Button>
                <Button color='primary'>Salvar</Button>
            </ModalFooter>
        </Modal>
    )

}