// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appRole/getAllData', async () => {
  const response = await api.get('/role')

  return response.data.data
})

export const getData = createAsyncThunk('appRole/getData', async params => {
  const response = await api.get('/role', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getRole = createAsyncThunk('appRole/getRole', async id => {
  const response = await api.get(`/role/${id}`)
  return response.data
})

export const setSaving = createAsyncThunk('appRole/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appRole/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appRole/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appRole/setError', async (data) => {
  return data
})

export const addRole = createAsyncThunk('appRole/addRole', async (role, { dispatch, getState }) => {
  try {
    const response = await api.post('/role', role)
    dispatch(getData(getState().roles.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateRole = createAsyncThunk('appRole/updateRole', async ({ id, role }, { dispatch }) => {
  try {
    const response = await api.put(`/role/${id}`, role)
    dispatch(getRole(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteRole = createAsyncThunk('appRole/deleteRole', async (id, { dispatch, getState }) => {
  await api.delete(`/role/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appRoleSlice = createSlice({
  name: 'appRole',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedRole: {},
    errors: {},
    saving: false,
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const role = state.data.find(u => u.id === id)

        if (role) state.selectedRole = role
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.selectedRole = action.payload
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addRole.pending, (state) => {
        state.saving = true
      })
      .addCase(addRole.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addRole.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateRole.pending, (state) => {
        state.saving = true
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.saving = false
        state.selectedRole = action.payload.data
      })
      .addCase(updateRole.rejected, (state) => {
        state.saving = false
      })

  }
})

export const { selectRole } = appRoleSlice.actions
export default appRoleSlice.reducer
