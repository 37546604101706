import '../../../../style.css'

import addphone from '../../../../assets/images/icons/addphone.png'

import ConnectionTypeModal from '../../../../views/apps/social-media/list/ConnectionTypeModal'

import EditSocialMediaModal from '../../../../views/apps/social-media/list/EditSocialMediaModal'

import { useState } from 'react'

import {
    UncontrolledTooltip,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'


import { Smartphone } from 'react-feather'
import { MdPhoneIphone } from "react-icons/md"

export default function Accounts() {

    const [connectionTypeOpen, setConnectionTypeOpen] = useState(false)
    const [editSocialMediaOpen, setEditSocialMediaOpen] = useState(false)

    const toggleConnectionType = () => setConnectionTypeOpen(!connectionTypeOpen)
    const toggleEditSocialMedia = () => setEditSocialMediaOpen(!editSocialMediaOpen)

    const tooltips = ['55279974460', '55279974460', '552730109510']
    const phoneStatus = [1]

    const getStatusText = (status) => {
        if (status === 0) {
            return 'Desconectado'
        } else if (status === 1) {
            return 'Conectado'
        } else if (status === 2) {
            return 'Não autenticado'
        }
    }

    const typeConnection = (statuses) => {
        const count0 = statuses.filter(status => status === 0).length
        const count2 = statuses.filter(status => status === 2).length

        if (count2) {
            return '#ff0000'
        } else if (count0) {
            return '#ff9f43'
        }

        return '#2fdd2f'
    }


    return (


        <div>
            <UncontrolledDropdown tag='li' >
                <DropdownToggle tag='a'
                >
                    <UncontrolledTooltip target='new-conversation-icons'>
                        Contas Conectadas
            </UncontrolledTooltip>
                    <Smartphone size={21} color={typeConnection(phoneStatus)} id='new-conversation-icons' />
                </DropdownToggle>
                <DropdownMenu end tag='ul'>

                    <li className='dropdown-menu-header'>
                        <DropdownItem className='d-flex' tag='div' header>
                            <h4 className='notification-title mb-0 me-auto'>Contas Conectadas</h4>
                        </DropdownItem>
                    </li>

                    <li>
                        <DropdownItem className='d-flex' tag='div' header>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                {phoneStatus.map((status, index) => (
                                    <div key={index}>
                                        <MdPhoneIphone size={24} color={typeConnection([status])} id={`phone-icon-${index}`} onClick={toggleEditSocialMedia} />
                                        <UncontrolledTooltip target={`phone-icon-${index}`} placement="bottom">
                                            {`${tooltips[index]} - ${getStatusText(status)}`}
                                        </UncontrolledTooltip>
                                    </div>
                                ))}
                                <UncontrolledTooltip target='add-account'>
                                    Adicionar Conta
            </UncontrolledTooltip>
                                <div style={{ marginLeft: '3px' }} className='d-flex justify-content-center align-items-center' >
                                    <img id='add-account' src={addphone} style={{ width: '22px', height: '22px' }} onClick={toggleConnectionType} />
                                </div>

                            </div>
                        </DropdownItem>
                    </li>


                </DropdownMenu>
            </UncontrolledDropdown>

            <ConnectionTypeModal open={connectionTypeOpen} toggleConnectionType={toggleConnectionType} />

            <EditSocialMediaModal open={editSocialMediaOpen} toggleEditSocialMedia={toggleEditSocialMedia} />

        </div>


    )
}