// ** Redux Imports
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import newMessageAudio from "../../../../assets/audio/new-message-sound.wav"
import newInternalMessageAudio from "../../../../assets/audio/new-internal-message.wav"

// ** api Imports
import api from "../../../../../src/utility/api"
import { clearParameterInputValues } from '../../../../../src/utility/Utils'

export const getUserProfile = createAsyncThunk('appConversation/getTasks', async () => {
  //const response = await api.get('/apps/chat/users/profile-user')
  return {
    id: 11,
    avatar: "https://monitchat.nyc3.cdn.digitaloceanspaces.com/app/profile/profile_pic.png",
    fullName: 'John Doe',
    role: 'admin',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  }
})

export const setSendingMedia = createAsyncThunk('appConversation/setSendingMedia', (id, { getState }) => {
  return !getState().sendingMedia
})

export const setSendingTemplate = createAsyncThunk('appConversation/setSendingTemplate', (sending) => {
  return sending
})

export const toggleContactTag = createAsyncThunk('appTag/toggleContactTag', async ({ contactId, tagId }, { getState }) => {
  try {
    const { selectedConversation } = getState().chat
    if (selectedConversation.tags.includes(tagId)) {
      await api.delete(`/contact-tag/${contactId}/${tagId}`)
      return { id: tagId, remove: true }
    } else {
      await api.post(`/contact-tag/${contactId}/${tagId}`)
      return { id: tagId, remove: false }
    }
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const toggleActivateBotLoading = createAsyncThunk('appConversation/toggleActivateBotLoading', () => { })

export const toggleConversationBot = createAsyncThunk('appConversationBot/toggleConversationBot', async (selectedConversation, { dispatch }) => {
  try {
    dispatch(toggleActivateBotLoading())
    await api.put(`/conversation-auto-reply/${selectedConversation.id}`, { active: !selectedConversation.auto_reply })
    dispatch(toggleActivateBotLoading())
    return { id: selectedConversation.id, auto_reply: !selectedConversation.auto_reply }
  } catch (err) {
    dispatch(toggleActivateBotLoading())
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const removeContactTag = createAsyncThunk('appTag/removeContactTag', async ({ contactId, tagId }, { getState }) => {
  try {
    const conversation = getState().chat.chats.find(c => c.id === contactId)
    await api.delete(`/contact-tag/${conversation.raw.contact.id}/${tagId}`)
    return { id: tagId, remove: true, chatId: contactId }

  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const setStartingConversation = createAsyncThunk('appConversation/setStartingConversation', (starting) => {
  return starting
})

export const setEndingConversation = createAsyncThunk('appConversation/setEndingConversation', (ending) => {
  return ending
})

export const closeChat = createAsyncThunk('appConversation/closeChat', (id) => {
  return id
})

export const sendMsg = createAsyncThunk('appConversation/sendMsg', (obj) => {
  api.post('/message', {
    ...obj
  })
  return obj
})

export const toggleInternalChatWindowClosed = createAsyncThunk('appConversation/toggleInternalChatWindowClosed', (id) => {
  return id
})

export const openInternalChatWindowMinimized = createAsyncThunk('appConversation/openInternalChatWindowMinimized', (id) => {
  return id
})

export const toggleInternalChatWindowMinimized = createAsyncThunk('appConversation/toggleInternalChatWindowMinimized', (id) => {
  return id
})

export const toggleSelectInternalChatWindow = createAsyncThunk('appConversation/toggleSelectInternalChatWindow', () => { })

export const openInternalChatWindow = createAsyncThunk('appConversation/openInternalChatWindow', (id) => {
  return id
})

export const sendTemplate = createAsyncThunk('appConversation/sendTemplate', (template, { dispatch }) => {
  dispatch(setSendingTemplate(true))

  const header = template.components.find(c => c.type === 'HEADER')

  const messageType = header ? (header.format === 'TEXT' ? 'chat' : header.format.toLowerCase()) : 'chat'
  template.message_type = messageType
  if (messageType !== 'chat') {
    const file = header.parameters[0]['HEADER-0'].file
    const body = new FormData()
    body.append("file", file)
    api.post(`/conversation-file/${template.conversation_id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      header.parameters[0]["HEADER-0"] = {
        type: header.format.toLowerCase(),
        [header.format.toLowerCase()]: {
          link: `${res.data.cdn}/${res.data.src}`
        }
      }
      if (header.format === "DOCUMENT") {
        header.parameters[0]["HEADER-0"]["document"] = {
          ...header.parameters[0]["HEADER-0"]["document"],
          filename: file.name
        }
      }

      template.components[0] = header

      api.post('/template-message', {
        ...template
      }).then(() => {
        dispatch(setSendingTemplate(false))
        clearParameterInputValues()
      })
    }).catch(() => {
      dispatch(setSendingTemplate(false))
    })
  } else {
    api.post('/template-message', {
      ...template
    }).then(() => {
      dispatch(setSendingTemplate(false))
      clearParameterInputValues()
    }).catch(() => {
      dispatch(setSendingTemplate(false))
    })
  }

  return template
})

export const uploadFile = createAsyncThunk('appConversation/uploadFile', ({ id, data, message }, { dispatch }) => {

  dispatch(setSendingMedia())

  const body = message.message_type !== 'audio' ? data : message
  api.post(`/conversation-file/${id}`, body, {
    headers: {
      'Content-Type': message.message_type !== 'audio' ? 'multipart/form-data' : 'application/json'
    }
  }).then(res => {

    dispatch(setSendingMedia())
    dispatch(sendMsg({
      ...message,
      src: message.file.src,
      file: {
        ...message.file,
        message_type: message.file.type,
        file_name: message.file.file_name,
        mime_type: message.file.mime_type,
        src: res.data.src
      }
    }))
  }).catch(() => dispatch(setSendingMedia()))
})

export const getEndChatTicketStatus = createAsyncThunk('appConversation/getEndChatTicketStatus', async () => {
  const response = await api.get('ticket-status', { params: { perPage: 30 } })
  return response.data.data
})

export const getEndChatUsers = createAsyncThunk('appConversation/getEndChatUsers', async () => {
  const response = await api.get('user', { params: { perPage: 150 } })
  return response.data.data
})

export const getInternalChats = createAsyncThunk('appConversation/getInternalChats', async ({ query = null, perPage = 15, skip = 0 }) => {
  const response = await api.get('/chat', { params: { query, perPage, page: parseInt(skip / perPage) } })

  return response.data
})

export const getConversations = createAsyncThunk('appConversation/getConversations', async ({ query = null, perPage = 15, skip }, { getState }) => {

  skip = !isNaN(skip) ? skip : getState().chat.skip

  const response = await api.get('/conversation', { params: { query, perPage, page: parseInt(skip / perPage) } })

  const conversations = response.data.data
  const payload = {
    contacts: [],
    conversations: conversations.map(c => ({
      id: c.id,
      fullName: c.contact.name,
      role: "",
      about: c.contact.additional_info,
      avatar: c.contact.avatar,
      tags: c.contact.tags,
      status: c.contact.status === 'unavailable' ? 'offline' : c.contact.status,
      chat: {
        id: c.id,
        unseenMsgs: c.unread_messages,
        activeTicketId: c.active_ticket_id,
        startedById: c.started_by_id,
        startedBy: c.started_by,
        lastMessage: {
          message: c.last_message.message,
          created_at: c.last_message.created_at_raw
        }
      },
      raw: c
    })),
    profileUser: {
      id: 11,
      avatar: "",
      fullName: "",
      status: ""
    }
  }

  return payload
})

export const archiveConversation = createAsyncThunk('appConversation/archiveConversation', async ({ id, archived }, { dispatch }) => {

  const response = await api.post(`/conversation-archive/${id}`, { archived })

  if (response.status >= 400) return false

  return id
})

export const toggleWalletLoading = createAsyncThunk('appConversation/toggleWalletLoading', () => { })

export const addToWallet = createAsyncThunk('appConversation/addToWallet', async ({ id }, { dispatch }) => {
  dispatch(toggleWalletLoading())
  const response = await api.post(`/contact-user/${id}`)
  dispatch(toggleWalletLoading())

  if (response.status >= 400) return false

  return id
})

export const removeFromWallet = createAsyncThunk('appConversation/removeFromWallet', async ({ id }, { dispatch }) => {
  dispatch(toggleWalletLoading())
  const response = await api.delete(`/contact-user/${id}`)

  if (response.status >= 400) return false

  return id
})

export const endConversation = createAsyncThunk('appConversation/endConversation', async ({ id, status, reason }, { getState, dispatch }) => {
  dispatch(setEndingConversation(true))
  try {
    const response = await api.post(`/conversation-end/${id}`, { status, reason })
    dispatch(setEndingConversation(false))
    return response.data
  } catch (err) {
    dispatch(setEndingConversation(false))
  }

  return {
    activeTicketId: getState().selectedConversation.activeTicketId,
    startedById: getState().selectedConversation.startedById,
    startedBy: getState().selectedConversation.startedBy,
    startingConversation: false
  }
})

export const forwardConversation = createAsyncThunk('appConversation/forwardConversation', async ({ id, user, reason }, { getState, dispatch }) => {
  dispatch(setEndingConversation(true))
  try {
    const response = await api.post(`/forward-conversation/${id}`, { user, reason })
    dispatch(setEndingConversation(false))
    return response.data
  } catch (err) {
    dispatch(setEndingConversation(false))
  }

  return {
    activeTicketId: getState().selectedConversation.activeTicketId,
    startedById: getState().selectedConversation.startedById,
    startedBy: getState().selectedConversation.startedBy,
    startingConversation: false
  }
})

export const commentConversation = createAsyncThunk('appConversation/commentConversation', async ({ id, reason }, { getState, dispatch }) => {
  dispatch(setEndingConversation(true))
  try {
    const response = await api.post(`/comment-conversation/${id}`, { reason })
    dispatch(setEndingConversation(false))
    return response.data
  } catch (err) {
    dispatch(setEndingConversation(false))
  }
})

export const changeConversationStatus = createAsyncThunk('appConversation/changeConversationStatus', async ({ id, status, reason }, { getState, dispatch }) => {
  dispatch(setEndingConversation(true))
  try {
    const response = await api.post(`/change-conversation-status/${id}`, { status, reason })
    dispatch(setEndingConversation(false))
    return response.data
  } catch (err) {
    dispatch(setEndingConversation(false))
  }

  return {
    activeTicketId: getState().selectedConversation.activeTicketId,
    startedById: getState().selectedConversation.startedById,
    startedBy: getState().selectedConversation.startedBy,
    startingConversation: false
  }
})

export const startConversation = createAsyncThunk('appConversation/startConversation', async ({ id }, { dispatch }) => {
  dispatch(setStartingConversation(true))
  try {
    const response = await api.post(`/conversation-start/${id}`)

    setTimeout(function () {
      document.getElementById('input-message').focus()
    }, 200)

    return response.data

  } catch (err) {
    dispatch(setStartingConversation(false))
  }

  return false
})

export const setConversationActive = createAsyncThunk('appConversation/setConversationActive', (id, { getState }) => {
  const { chats } = getState().chat

  const conversation = chats.find(c => c.id === id)

  return {
    ...conversation.raw,
    startedById: conversation.raw.started_by_id,
    startedBy: conversation.raw.started_by,
    activeTicketId: conversation.raw.active_ticket_id,
    tags: conversation.tags,
    contact: {
      ...conversation.raw.contact,
      status: conversation.raw.contact.status === "unavailable" ? "offline" : conversation.raw.contact.status
    }
  }
})

export const getConversation = createAsyncThunk('appConversation/getConversation', async (id, { getState }) => {

  if (!id) return {}

  const { chats } = getState().chat

  const chat = chats.find(
    (c) => c.id === id && c.chat.loaded
  )

  if (chat) {
    return chat.chat
  }

  const response = await api.get(`/conversation/${id}`)

  const messages = [
    ...response.data.messages,
    ...response.data.comments
  ].sort(function (a, b) {
    return new Date(a.created_at) - new Date(b.created_at)
  })

  const conversation = {
    ...response.data,
    loaded: true,
    startedById: response.data.started_by_id,
    startedBy: response.data.started_by,
    activeTicketId: response.data.active_ticket_id,
    tags: response.data.contact.tags,
    messages,
    contact: {
      ...response.data.contact,
      status: response.data.contact.status === "unavailable" ? "offline" : response.data.contact.status
    }
  }

  return conversation
})

export const getMessages = createAsyncThunk('appConversation/getMessages', async (params, { getState }) => {

  const { messageId, search } = params
  const { selectedConversation } = getState().chat
  const response = await api.get(`/conversation/${selectedConversation.id}/messages?q=${search}&fromId=${messageId}&perPage=1000`)

  const conversation = {
    ...selectedConversation,
    messages: [...response.data, ...selectedConversation.messages]
  }

  return conversation
})

export const setChatInternalLoading = createAsyncThunk('appConversation/setChatInternalLoading', (id) => {
  return id
})

export const getInternalChatMessages = createAsyncThunk('appConversation/getInternalChatMessages', async (id, { getState, dispatch }) => {

  const internals = getState().chat.internal
  const messages = internals.find(c => c.id === id).messages

  if (messages && !messages.length) {
    dispatch(setChatInternalLoading(id))
  }

  const response = await api.get(`/chat-message/${id}?perPage=50`)

  return { id, messages: response.data.data }
})

export const addInternalChatMessages = createAsyncThunk('appConversation/addInternalChatMessages', ({ chat_id, text, type, from }, { getState }) => {
  api.post(`/chat-message/${chat_id}`, {
    chat_id,
    text,
    type,
    from
  })

  return { chat_id, text, type, from }
})

export const messageConversationUpdated = createAsyncThunk('appConversation/messageConversationUpdated', (message) => {

  return message
})

export const conversationChanged = createAsyncThunk('appConversation/conversationChanged', (data) => {
  return data
})

export const commentCreated = createAsyncThunk('appConversation/commentCreated', async (comment, { getState }) => {
  const { chats } = getState().chat
  const { conversation_id: conversationId } = comment

  const conversation = chats.find(
    (c) => c.id === conversationId
  )

  if (conversation) {
    return {
      comment,
      conversation
    }
  }
})

export const messageReceived = createAsyncThunk('appConversation/messageReceived', async (data, { getState }) => {
  const { chats } = getState().chat

  const { data: message } = data

  const { conversation_id: conversationId } = message

  let conversation = chats.find(
    (c) => c.id === conversationId
  )

  const types = {
    0: 'chat',
    1: 'video',
    2: 'audio',
    3: 'document',
    4: 'image',
    5: 'audio',
    6: 'contact'
  }

  if (!conversation) {
    const res = await api.get(`/conversation/${conversationId}`)
    conversation = {
      ...res.data,
      contact: {
        ...res.data.contact,
        status: res.data.contact.status === "unavailable" ? "offline" : res.data.contact.status
      }
    }
  }

  // const audio = new Audio(newMessageAudio)
  // audio.play()


  return {
    message: {
      ...message,
      message_type: types[message.message_type]
    },
    conversation
  }
})

export const messageAnswered = createAsyncThunk('appConversation/messageAnswered', async (data, { getState }) => {
  const { chats } = getState().chat

  const { data: message } = data

  const { conversation_id: conversationId } = message

  let conversation = chats.find(
    (c) => c.id === conversationId
  )

  const types = {
    0: 'chat',
    1: 'video',
    2: 'audio',
    3: 'document',
    4: 'image',
    5: 'audio',
    6: 'contact'
  }

  if (!conversation) {
    const res = await api.get(`/conversation/${conversationId}`)
    conversation = {
      ...res.data,
      contact: {
        ...res.data.contact,
        status: res.data.contact.status === "unavailable" ? "offline" : res.data.contact.status
      }
    }
  }

  // const audio = new Audio(newMessageAudio)
  // audio.play()


  return {
    message: {
      ...message,
      message_type: types[message.message_type]
    },
    conversation
  }
})

export const internalMessageReceived = createAsyncThunk('appConversation/internalMessageReceived', async (data, { dispatch }) => {

  dispatch(openInternalChatWindowMinimized(data.chat_id))

  // const audio = new Audio(newInternalMessageAudio)
  // audio.play()

  return data
})

export const appConversationSlice = createSlice({
  name: 'appConversation',
  initialState: {
    chats: [],
    internal: [],
    sendingMedia: false,
    startingConversation: false,
    endingConversation: false,
    contacts: [],
    sendingTemplate: false,
    userProfile: {},
    selectedConversation: {},
    ticketStatuses: [],
    usersAvailable: [],
    walletLoading: false,
    activateBotLoading: false,
    selectInternalChatWindowOpen: false,
    skip: 15
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(startConversation.fulfilled, (state, action) => {
        const { active_ticket_id: activeTicketId, started_by_id: startedById, started_by: startedBy } = action.payload
        state.selectedConversation.activeTicketId = activeTicketId
        state.selectedConversation.startedById = startedById
        state.selectedConversation.startedBy = startedBy
        state.startingConversation = false
      })
      .addCase(addToWallet.fulfilled, (state, action) => {
        const contactId = action.payload
        const chat = state.chats.find(c => c.chat.contact && c.chat.contact.id === contactId)

        if (chat.chat.contact) {
          const index = state.chats.findIndex((c) => c.id === chat.id)
          state.chats[index].chat.contact.is_my_contact = true
        }
        state.selectedConversation.contact.is_my_contact = true
      })
      .addCase(toggleWalletLoading.fulfilled, (state, action) => {
        state.walletLoading = !state.walletLoading
      })
      .addCase(toggleActivateBotLoading.fulfilled, (state, action) => {
        state.activateBotLoading = !state.activateBotLoading
      })
      .addCase(removeFromWallet.fulfilled, (state, action) => {
        const contactId = action.payload
        const chat = state.chats.find(c => c.chat.contact && c.chat.contact.id === contactId)

        if (chat.chat.contact) {
          const index = state.chats.findIndex((c) => c.id === chat.id)
          state.chats[index].chat.contact.is_my_contact = false
        }

        state.selectedConversation.contact.is_my_contact = false
        state.walletLoading = !state.walletLoading
      })
      .addCase(endConversation.fulfilled, (state, action) => {
        const { active_ticket_id: activeTicketId, started_by_id: startedById, started_by: startedBy } = action.payload
        state.selectedConversation.activeTicketId = activeTicketId
        state.selectedConversation.startedById = startedById
        state.selectedConversation.startedBy = startedBy
        state.startingConversation = false
      })
      .addCase(forwardConversation.fulfilled, (state, action) => {
        const { active_ticket_id: activeTicketId, started_by_id: startedById, started_by: startedBy } = action.payload
        state.selectedConversation.activeTicketId = activeTicketId
        state.selectedConversation.startedById = startedById
        state.selectedConversation.startedBy = startedBy
        state.startingConversation = false
      })
      .addCase(archiveConversation.fulfilled, (state, action) => {

        if (action.payload) {
          if (action.payload === state.selectedConversation.id) state.selectedConversation = {}
          state.chats = state.chats.filter(c => c.id !== action.payload)
        }

      })
      .addCase(toggleInternalChatWindowMinimized.fulfilled, (state, action) => {
        const index = state.internal.findIndex((c) => c.id === action.payload)

        state.internal[index] = {
          ...state.internal[index],
          window_minimized: !state.internal[index].window_minimized
        }
      })
      .addCase(openInternalChatWindowMinimized.fulfilled, (state, action) => {

        const index = state.internal.findIndex((c) => c.id === action.payload)

        if (state.internal[index].window_closed) {
          state.internal[index] = {
            ...state.internal[index],
            window_minimized: true,
            window_closed: false
          }
        }

      })
      .addCase(toggleInternalChatWindowClosed.fulfilled, (state, action) => {

        const index = state.internal.findIndex((c) => c.id === action.payload)

        state.internal[index] = {
          ...state.internal[index],
          window_closed: !state.internal[index].window_closed
        }

      })
      .addCase(toggleSelectInternalChatWindow.fulfilled, (state) => {

        state.selectInternalChatWindowOpen = !state.selectInternalChatWindowOpen
      })
      .addCase(setChatInternalLoading.fulfilled, (state, action) => {

        const index = state.internal.findIndex(i => i.id === action.payload)
        state.internal[index].loading = !state.internal[index].loading
      })
      .addCase(openInternalChatWindow.fulfilled, (state, action) => {

        const index = state.internal.findIndex((c) => c.id === action.payload)

        state.internal[index] = {
          ...state.internal[index],
          window_closed: false,
          window_minimized: false
        }

        state.selectInternalChatWindowOpen = false

      })
      .addCase(toggleContactTag.fulfilled, (state, action) => {

        const index = state.chats.findIndex((c) => c.id === state.selectedConversation.id)

        if (action.payload.remove) {
          state.selectedConversation.tags = state.selectedConversation.tags.filter(t => t !== action.payload.id)
          state.chats[index].tags = state.chats[index].tags.filter(t => t !== action.payload.id)
        } else {
          state.selectedConversation.tags = [
            ...state.selectedConversation.tags,
            action.payload.id
          ]

          state.chats[index].tags = [
            ...state.chats[index].tags,
            action.payload.id
          ]
        }
      })
      .addCase(toggleConversationBot.fulfilled, (state, action) => {

        const index = state.chats.findIndex((c) => c.id === action.payload.id)
        state.chats[index].auto_reply = action.payload.auto_reply
        state.selectedConversation.auto_reply = action.payload.auto_reply

      })
      .addCase(removeContactTag.fulfilled, (state, action) => {
        const index = state.chats.findIndex((c) => c.id === action.payload.chatId)
        state.chats[index].tags = state.chats[index].tags.filter(t => t !== action.payload.id)
      })
      .addCase(setSendingMedia.fulfilled, (state, action) => {
        state.sendingMedia = action.payload
      })
      .addCase(setStartingConversation.fulfilled, (state, action) => {
        state.startingConversation = action.payload
      })
      .addCase(setEndingConversation.fulfilled, (state, action) => {
        state.endingConversation = action.payload
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getEndChatUsers.fulfilled, (state, action) => {
        state.usersAvailable = action.payload
      })
      .addCase(getEndChatTicketStatus.fulfilled, (state, action) => {
        state.ticketStatuses = action.payload
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        const chatIds = state.chats.map(c => c.id)
        state.chats = [
          ...state.chats,
          ...action.payload.conversations.filter(c => chatIds.indexOf(c.id) < 0)
        ]

        const contactIds = state.contacts.map(c => c.id)
        state.contacts = [
          ...state.contacts,
          ...action.payload.contacts.filter(c => contactIds.indexOf(c.id) < 0)
        ]

        state.skip = action.payload.conversations.length + state.skip
      })
      .addCase(getInternalChats.fulfilled, (state, action) => {
        state.internal = action.payload.map(i => ({ ...i, window_closed: true, window_minimized: true }))
      })
      .addCase(closeChat.fulfilled, (state, action) => {
        state.selectedConversation = {}
      })
      .addCase(getConversation.fulfilled, (state, action) => {
        state.selectedConversation = action.payload
        const index = state.chats.findIndex((c) => c.id === action.payload.id)
        state.chats[index].chat = {
          ...action.payload,
          tags: action.payload.tags,
          unseenMsgs: action.payload.unread_messages,
          activeTicketId: action.payload.active_ticket_id,
          startedById: action.payload.started_by_id,
          startedBy: action.payload.started_by,
          lastMessage: {
            message: action.payload.last_message.message,
            created_at: action.payload.last_message.created_at_raw
          }
        }

        setTimeout(function () {
          document.getElementById('input-message')?.focus()
        }, 200)
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.selectedConversation = action.payload
      })
      .addCase(setConversationActive.fulfilled, (state, action) => {
        state.selectedConversation = action.payload

        setTimeout(function () {
          document.getElementById('input-message').focus()
        }, 200)
      })
      .addCase(setSendingTemplate.fulfilled, (state, action) => {
        state.sendingTemplate = action.payload
      })
      .addCase(getInternalChatMessages.fulfilled, (state, action) => {

        const index = state.internal.findIndex((c) => c.id === action.payload.id)
        state.internal[index].messages = action.payload.messages
        state.internal[index].loading = false
      })
      .addCase(addInternalChatMessages.fulfilled, (state, action) => {

        const index = state.internal.findIndex((c) => c.id === action.payload.chat_id)

        state.internal[index].messages = [
          ...state.internal[index].messages,
          {
            text: action.payload.text,
            type: action.payload.type,
            from: action.payload.from
          }
        ]
      })
      .addCase(sendMsg.fulfilled, (state, action) => {

        const timestamp = Date.now()
        const date = new Date(timestamp * 1000)
        const iso = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/)

        const human_date = new Date(timestamp).toLocaleString()

        const newMessage = {
          message_type: action.payload.message_type,
          src: action.payload.src,
          message_token: action.payload.message_token,
          message: action.payload.message,
          message_id: action.payload.message_token,
          id: action.payload.message_token,
          created_at: `${iso[1]} ${iso[2]}`,
          human_date,
          sender: 1,
          status: 0,
          user: action.payload.user
        }

        state.selectedConversation.messages = [...state.selectedConversation.messages, newMessage]
        state.selectedConversation.last_message = newMessage

        const index = state.chats.findIndex((c) => c.id === state.selectedConversation.id)
        state.chats[index].chat = { ...state.chats[index].chat, messages: state.selectedConversation.messages, lastMessage: state.selectedConversation.last_message }
        state.chats[index].messages = state.selectedConversation.messages
        state.sendingMedia = false
      })
      .addCase(commentCreated.fulfilled, (state, action) => {

        let { conversation } = action.payload
        const { comment } = action.payload

        const index = state.chats.findIndex((c) => c.id === conversation.id)
        if (index > -1) {
          conversation = state.chats.find(
            (c) => c.id === conversation.id
          )

          if (conversation.messages) {
            conversation.messages.push(comment)
            conversation.chat.messages.push(comment)
          } else {
            conversation.messages = [comment]
          }

          state.chats = [
            conversation,
            ...state.chats.slice(0, index),
            ...state.chats.slice(index + 1)
          ]

          if (state.selectedConversation.id) {
            if (state.selectedConversation.id === comment.conversation_id) {
              state.selectedConversation.messages.push(comment)
            }
          }
        }
      })
      .addCase(internalMessageReceived.fulfilled, (state, action) => {

        const { chat_id, from, type, text, avatar, user } = action.payload
        const index = state.internal.findIndex((c) => c.id === chat_id)

        state.internal[index].messages = [
          ...state.internal[index].messages,
          {
            text,
            type,
            from,
            avatar,
            user
          }
        ]

        state.internal[index].unread_messages = state.internal[index].unread_messages + 1

      })
      .addCase(messageReceived.fulfilled, (state, action) => {

        let { conversation } = action.payload
        const { message } = action.payload

        const index = state.chats.findIndex((c) => c.id === conversation.id)
        if (index > -1) {
          conversation = state.chats.find(
            (c) => c.id === conversation.id
          )

          if (message.src && message.src.indexOf('https') === -1) {
            message.src = `https://monitchat.nyc3.cdn.digitaloceanspaces.com/app/${message.src}`
          }

          if (conversation.messages) {
            conversation.messages.push(message)
          } else {
            conversation.messages = [message]
          }

          conversation.chat.lastMessage = {
            message: message.message,
            created_at: message.created_at
          }

          conversation.chat.unseenMsgs = conversation.chat.unseenMsgs + 1

          state.chats = [
            conversation,
            ...state.chats.slice(0, index),
            ...state.chats.slice(index + 1)
          ]

          if (state.selectedConversation.id) {
            if (state.selectedConversation.id === message.conversation_id) {
              state.selectedConversation.messages.push(message)
              state.selectedConversation.lastMessage = {
                message: message.message,
                created_at: message.created_at
              }
            }
          }
        } else {
          const { contact } = conversation
          conversation = {
            ...conversation,
            avatar: contact.avatar,
            fullName: contact.name,
            about: contact.additional_info,
            status: contact.status === "unavailable" ? "offline" : contact.status,
            contact: {
              ...conversation.contact,
              status: contact.status === "unavailable" ? "offline" : contact.status
            },
            chat: {
              unseenMsgs: conversation.unread_messages,
              activeTicketId: conversation.active_ticket_id,
              startedById: conversation.started_by_id,
              startedBy: conversation.started_by,
              lastMessage: {
                message: message.message,
                created_at: message.created_at
              }
            }
          }

          state.chats = [conversation, ...state.chats]
        }
      })
      .addCase(messageAnswered.fulfilled, (state, action) => {

        let { conversation } = action.payload
        const { message } = action.payload

        const index = state.chats.findIndex((c) => c.id === conversation.id)
        if (index > -1) {
          conversation = state.chats.find(
            (c) => c.id === conversation.id
          )

          if (message.src && message.src.indexOf('https') === -1) {
            message.src = `https://monitchat.nyc3.cdn.digitaloceanspaces.com/app/${message.src}`
          }

          if (conversation.messages) {
            conversation.messages.push(message)
          } else {
            conversation.messages = [message]
          }

          conversation.chat.lastMessage = {
            message: message.message,
            created_at: message.created_at
          }

          conversation.chat.unseenMsgs = conversation.chat.unseenMsgs + 1

          state.chats = [
            conversation,
            ...state.chats.slice(0, index),
            ...state.chats.slice(index + 1)
          ]

          if (state.selectedConversation.id) {
            if (state.selectedConversation.id === message.conversation_id) {
              state.selectedConversation.messages.push(message)
              state.selectedConversation.lastMessage = {
                message: message.message,
                created_at: message.created_at
              }
            }
          }
        } else {
          const { contact } = conversation
          conversation = {
            ...conversation,
            avatar: contact.avatar,
            fullName: contact.name,
            about: contact.additional_info,
            status: contact.status === "unavailable" ? "offline" : contact.status,
            contact: {
              ...conversation.contact,
              status: contact.status === "unavailable" ? "offline" : contact.status
            },
            chat: {
              unseenMsgs: conversation.unread_messages,
              activeTicketId: conversation.active_ticket_id,
              startedById: conversation.started_by_id,
              startedBy: conversation.started_by,
              lastMessage: {
                message: message.message,
                created_at: message.created_at
              }
            }
          }

          state.chats = [conversation, ...state.chats]
        }
      })
      .addCase(messageConversationUpdated.fulfilled, (state, action) => {
        const { data: message } = action.payload
        const { conversation_id: conversationId } = message
        const conversation = state.chats.find(
          (c) => c.id === conversationId
        )

        if (conversation) {

          const index = state.chats.findIndex((c) => c.id === conversation.id)
          if (conversation.chat.messages) {
            const messageIndex = conversation.chat.messages.findIndex((c) => c.message_token === message.message_token)

            if (messageIndex > -1) {
              conversation.chat.messages[messageIndex] = {
                ...conversation.chat.messages[messageIndex],
                status: message.status,
                id: message.id
              }
            } else {
              conversation.chat.messages.push(message)
            }
          } else {
            conversation.chat.messages = [{ ...message }]
          }

          state.chats = [
            ...state.chats.slice(0, index),
            conversation,
            ...state.chats.slice(index + 1)
          ]

          if (state.selectedConversation && state.selectedConversation.id === conversationId) {
            const messages = state.selectedConversation.messages
            if (messages) {
              const currentMessage = messages.find(m => m.message_token === message.message_token)

              if (currentMessage) {
                const messageIndex = messages.findIndex(m => m.message_token === message.message_token)
                state.selectedConversation.messages[messageIndex] = {
                  ...state.selectedConversation.messages[messageIndex],
                  status: message.status,
                  id: message.id
                }
              } else {
                state.selectedConversation.messages.push(message)
              }
            }
          }
        }
      })
      .addCase(conversationChanged.fulfilled, (state, action) => {
        const { data } = action.payload

        const { conversation_id: id } = data
        const conversation = state.chats.find(
          (c) => c.id === id
        )

        if (conversation) {

          const index = state.chats.findIndex((c) => c.id === conversation.id)

          state.chats = [
            ...state.chats.slice(0, index),
            {
              ...conversation,
              activeTicketId: data.active_ticket_id,
              startedById: data.started_by_id,
              startedBy: data.started_by
            },
            ...state.chats.slice(index + 1)
          ]

          if (state.selectedConversation && state.selectedConversation.id === id) {
            state.selectedConversation = {
              ...state.selectedConversation,
              activeTicketId: data.active_ticket_id,
              startedById: data.started_by_id,
              startedBy: data.started_by
            }
          }
        }
      })
  }
})

export default appConversationSlice.reducer
