
// ** Reactstrap Imports
import {
    Row,
    Form,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    Spinner
} from 'reactstrap'

export default function FormOfficial() {
    return (

        <Form >
            <Row>
                <Col md='6' sm='12' className='mb-1'>
                    <div className='mb-1'>
                        <Label>
                            Número da conta:
             </Label>
                        <Input size='sm' />
                    </div>

                    <div className='mb-1'>
                        <Label>
                            Api Key:
           </Label>
                        <Input size='sm' />
                    </div>

                    <div>
                        <Label>
                            ID Cliente (360 Dialog)
          </Label>
                        <Input size='sm' />
                    </div>


                </Col>
                <Col md='6' sm='12' className='mb-1'>

                    <div className='mb-1'>
                        <Label>
                            Id Projeto (360 Dialog)
          </Label>

                        <Input size='sm' />
                    </div>

                    <div className='mb-1'>
                        <Label>
                            Id Cliente (360 Dialog)
          </Label>
                        <Input size='sm' />
                    </div>
                </Col>

                <Col md='12' sm='12' className='mb-1'>
                    <div className='mb-1'>
                        <Label>
                            Account ID: (para cadastro direto no facebook)
          </Label>

                        <Input size='sm' />
                    </div>

                    <div className='mb-1'>
                        <Label>
                            Business Id: (para cadastro direto no facebook)
          </Label>
                        <Input size='sm' />
                    </div>

                    <div className='mb-1'>
                        <Label>
                            Nome do Assistente Virtual
          </Label>
                        <Input size='sm' />
                    </div>

                </Col>
                <div className='d-flex aligm-itens-center flex-column'>

                    <div className='form-check form-check-inline'>
                        <Label className='form-check-label'>
                            Envio em Massa?
                </Label>
                        <Input type='checkbox' />

                    </div>

                    <div className='form-check form-check-inline'>
                        <Label className='form-check-label'>
                            Atendimento Automatizado?
                </Label>
                        <Input type='checkbox' />
                    </div>
                    <div className='form-check form-check-inline'>
                        <Label className='form-check-label'>
                            Pode responder?
                </Label>
                        <Input type='checkbox' />
                    </div>
                    <div className='form-check form-check-inline'>
                        <Label className='form-check-label'>
                            Enviar e-mail quando a conta perder autentificação?
                </Label>
                        <Input type='checkbox' />
                    </div>
                </div>

                <div className=' d-flex flex-column align-items-center'>
                    <button style={{ padding: '7px', color: 'white', backgroundColor: '#ff4369', border: 'none', borderRadius: '6px', fontSize: '1.2em', marginTop: '2.5em' }}>
                        Conectar com a 360 Dialog
            </button>
                </div>

            </Row>


        </Form>

    )

}