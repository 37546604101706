import '../../../../style.css'

import  { useState } from 'react'
import FormOfficial from './EditForms/EditFormOfficial'
import CreationUnofficialForm from './CreationForms/CreationUnofficialForm'

import {
    Row,
    Form,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    Spinner
} from 'reactstrap'

import { BsQrCodeScan, BsList } from "react-icons/bs"


export default function ConnectionTypeModal({open, toggleConnectionType}) {

 const [selectedForm, setSelectedForm] = useState(null)

  const selectUnofficialForm = () => {
    setSelectedForm('unofficial')
  }

  const selectOfficialForm = () => {
    setSelectedForm('official')
  }

    return (

            <Modal className='modal-dialog-centered modal-md' isOpen={open} >
                <ModalHeader toggle={toggleConnectionType} >
                    Escolha o tipo de conexão
            </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className='d-flex justify-content-center '>
                                <button className='btn-choose-connection' onClick={selectUnofficialForm}>
                                        <span>                                       Ler QRCode
                                        </span>
                                    <BsQrCodeScan size={45} />
                                </button>
                            </div>
                        </Col>
                        <Col>
                            <div className='d-flex justify-content-center'>
                                <button className='btn-choose-connection ' onClick={selectOfficialForm}>
                                    
                                        API Oficial
       
                                    <BsList size={45}/>
                            
                                </button>


                            </div>
                        </Col>
                    </Row>
                    
                </ModalBody>
                <ModalFooter>
                {selectedForm === 'unofficial' && <CreationUnofficialForm  />}
        {selectedForm === 'official' && <FormOfficial />}
      </ModalFooter>
              
              
            </Modal>
        
    )
}