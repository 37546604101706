// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const Chat = lazy(() => import('../../views/apps/chat'))
const Todo = lazy(() => import('../../views/apps/todo'))
const Email = lazy(() => import('../../views/apps/email'))
const Kanban = lazy(() => import('../../views/apps/kanban'))
const Calendar = lazy(() => import('../../views/apps/calendar'))
const Bot = lazy(() => import('../../views/apps/bot'))

const UserList = lazy(() => import('../../views/apps/user/list'))

const ContactList = lazy(() => import('../../views/apps/contact/list'))

const DepartmentList = lazy(() => import('../../views/apps/department/list'))

const TicketList = lazy(() => import('../../views/apps/ticket/list'))

const GroupList = lazy(() => import('../../views/apps/group/list'))

const ClientList = lazy(() => import('../../views/apps/client/list'))

const CampaignList = lazy(() => import('../../views/apps/campaign/list'))

const UsersList = lazy(() => import('../../views/apps/users/list'))

const TicketStatusList = lazy(() => import('../../views/apps/ticket-status/list'))

const TicketCategoryList = lazy(() => import('../../views/apps/ticket-category/list'))

const InterruptionTypeList = lazy(() => import('../../views/apps/interruption-type/list'))

const DissatisfactionTypeList = lazy(() => import('../../views/apps/dissatisfaction-type/list'))

const SocialMediaList = lazy(() => import('../../views/apps/social-media/list'))

const QuickMessageList = lazy(() => import('../../views/apps/quick-message/list'))

const BadWordList = lazy(() => import('../../views/apps/bad-word/list'))

const AlertWordList = lazy(() => import('../../views/apps/alert-word/list'))

const ServerList = lazy(() => import('../../views/apps/server/list'))

const WebhookList = lazy(() => import('../../views/apps/webhook/list'))

const PageList = lazy(() => import('../../views/apps/page/list'))

const ExtraInputList = lazy(() => import('../../views/apps/extra-input/list'))

const QuotaList = lazy(() => import('../../views/apps/quotas/list'))

const CompanyList = lazy(() => import('../../views/apps/company/list'))

const Roles = lazy(() => import('../../views/apps/roles-permissions/roles'))
const Permissions = lazy(() => import('../../views/apps/roles-permissions/permissions'))

const AppRoutes = [
  {
    path: '/apps/chat',
    element: <Chat />,
    meta: {
      appLayout: true,
      className: 'chat-application'
    }
  },
  {
    element: <Todo />,
    path: '/apps/todo',
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  {
    element: <Todo />,
    path: '/apps/todo/:filter',
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  {
    element: <ContactList />,
    path: '/apps/contact'
  },
  {
    element: <DepartmentList />,
    path: '/apps/department'
  },
  {
    element: <TicketList />,
    path: '/apps/ticket'
  },
  {
    element: <ClientList />,
    path: '/apps/client'
  },
  {
    element: <GroupList />,
    path: '/apps/group'
  },
  {
    element: <CampaignList />,
    path: '/apps/campaign'
  },
  {
    element: <UsersList />,
    path: '/apps/users'
  },
  {
    element: <TicketStatusList />,
    path: '/apps/ticket-status'
  },
  {
    element: <TicketCategoryList />,
    path: '/apps/ticket-category'
  },
  {
    element: <InterruptionTypeList />,
    path: '/apps/interruption-type'
  },
  // {
  //   element: <InstagramList />,
  //   path: '/apps/instagram'
  // },
  {
    element: <DissatisfactionTypeList />,
    path: '/apps/dissatisfaction-type'
  },
  {
    element: <SocialMediaList />,
    path: '/apps/social-media'
  },
  {
    element: <QuickMessageList />,
    path: '/apps/quick-message'
  },
  {
    element: <BadWordList />,
    path: '/apps/bad-word'
  },
  {
    element: <AlertWordList />,
    path: '/apps/alert-word'
  },
  {
    element: <ServerList />,
    path: '/apps/server'
  },
  {
    element: <WebhookList />,
    path: '/apps/webhook'
  },
  {
    element: <PageList />,
    path: '/apps/page'
  },
  {
    element: <ExtraInputList />,
    path: '/apps/extra-input'
  },
  {
    element: <QuotaList />,
    path: '/apps/quotas'
  },
  {
    element: <CompanyList />,
    path: '/apps/company'
  },
  {
    element: <Todo />,
    path: '/apps/todo/tag/:tag',
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  {
    element: <Calendar />,
    path: '/apps/calendar'
  },
  {
    element: <Kanban />,
    path: '/apps/kanban',
    meta: {
      appLayout: true,
      className: 'kanban-application'
    }
  },
  {
    element: <Bot />,
    path: '/apps/bot',
    meta: {
      appLayout: true,
      className: 'bot-application'
    }
  },
  {
    element: <UserList />,
    path: '/apps/user/list'
  },
  {
    element: <Roles />,
    path: '/apps/roles'
  },
  {
    element: <Permissions />,
    path: '/apps/permissions'
  }
]

export default AppRoutes
