import '../../../../style.css'

import {
    UncontrolledTooltip
} from 'reactstrap'

import {
    BsBook
} from "react-icons/bs"

export default function KnowledgeBase() {
    return (

        <div >
            <UncontrolledTooltip target='base'>
                Base de Conhecimento
            </UncontrolledTooltip>
            <a id='base' target='_blank' href='https://knowledge.monitchat.com/'
                className='visited-link'>
                <BsBook size={22} id='base' />
            </a>
        </div>
    )
}