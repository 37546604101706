// ** Icons Import
import {
  Mail,
  MessageSquare,
  CheckSquare,
  Calendar, Circle, User, Shield, Target, Settings, Users, Trello, Lock, Pause, AlertTriangle, AlertOctagon, Server, List, Zap, FileText
} from 'react-feather'

import { FaWhatsapp, FaInstagram, FaUsers, FaTelegramPlane, FaFacebookSquare, FaTicketAlt } from "react-icons/fa"

export default [
  {
    header: 'Inicio'
  },
  /* {
    id: 'email',
    title: 'Email',
    icon: <Mail size={20} />,
    navLink: '/apps/email'
  }, */
  {
    id: 'chat',
    title: 'Chat',
    icon: <MessageSquare size={20} />,
    navLink: '/apps/chat'
  },
  {
    id: 'tickets',
    title: 'Tickets',
    icon: <FaTicketAlt size={20} />,
    navLink: '/apps/ticket'
  },
  {
    id: 'todo',
    title: 'Tarefas',
    icon: <CheckSquare size={20} />,
    navLink: '/apps/todo'
  },
  {
    id: 'calendar',
    title: 'Calendar',
    icon: <Calendar size={20} />,
    navLink: '/apps/calendar'
  },
  {
    id: 'relationship',
    title: 'Relationship',
    icon: <Users size={20} />,
    children: [
      {
        id: 'contacts',
        title: 'Contact',
        icon: <Circle size={12} />,
        navLink: '/apps/contact'
      },
      {
        id: 'clients',
        title: 'Client',
        icon: <Circle size={12} />,
        navLink: '/apps/client'
      },
      {
        id: 'groups',
        title: 'Grupos',
        icon: <Users size={12} />,
        navLink: '/apps/group'
      }
    ]
  },
  {
    id: 'kanban',
    title: 'Kanban',
    icon: <Trello size={20} />,
    navLink: '/apps/kanban'
  },
  {
    id: 'campaign',
    title: 'Campaign',
    icon: <Target size={20} />,
    navLink: '/apps/campaign'
  },
  /* {
    id: 'invoiceApp',
    title: 'Invoice',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'invoiceList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/list'
      },
      {
        id: 'invoicePreview',
        title: 'Preview',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/preview'
      },
      {
        id: 'invoiceEdit',
        title: 'Edit',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/edit'
      },
      {
        id: 'invoiceAdd',
        title: 'Add',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/add'
      }
    ]
  }, */
  {
    id: 'registration',
    title: 'Registration',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'department',
        title: 'Department',
        icon: <Users size={12} />,
        navLink: '/apps/department'
      },
      {
        id: 'ticket-status',
        title: 'Ticket Status',
        icon: <Circle size={12} />,
        navLink: '/apps/ticket-status'
      },
      {
        id: 'interruption-type',
        title: 'Interruption Type',
        icon: <Pause size={12} />,
        navLink: '/apps/interruption-type'
      },
      {
        id: 'bad-word',
        title: 'Bad Words',
        icon: <AlertOctagon size={12} />,
        navLink: '/apps/bad-word'
      },
      {
        id: 'alert-word',
        title: 'Alert Words',
        icon: <AlertTriangle size={12} />,
        navLink: '/apps/alert-word'
      },
      {
        id: 'server',
        title: 'Servers',
        icon: <Server size={12} />,
        navLink: '/apps/server'
      },
      {
        id: 'dissatisfaction-type',
        title: 'Dissatisfaction Type',
        icon: <List size={12} />,
        navLink: '/apps/dissatisfaction-type'
      },
      {
        id: 'webhook',
        title: 'Webhook',
        icon: <Zap size={12} />,
        navLink: '/apps/webhook'
      },
      {
        id: 'quick-message',
        title: 'Quick Message',
        icon: <Mail size={12} />,
        navLink: '/apps/quick-message'
      }
    ]
  },
  // {
  //   id: 'integration',
  //   title: 'Integration',
  //   icon: <FaWhatsapp size={20} />,
  //   navLink: '/apps/calendar'
  // },
  {
    id: 'social-media',
    title: 'Social Media',
    icon: <FaUsers size={20} />,
    children: [
      {
        id: 'whatsapp',
        title: 'WhatsApp',
        icon: <FaWhatsapp size={20} />,
        navLink: '/apps/social-media'
      },
      {
        id: 'instagram',
        title: 'Instagram',
        icon: <FaInstagram size={20} />,
        navLink: '/pages/instagram'
      },
      {
        id: 'telegram',
        title: 'Telegram',
        icon: <FaTelegramPlane size={20} />,
        navLink: '/pages/telegram'
      },

      {
        id: 'facebook',
        title: 'Facebook',
        icon: <FaFacebookSquare size={20} />,
        navLink: '/pages/facebook'
      }

    ]
  },
  {
    id: 'roles-permissions',
    title: 'Security',
    icon: <Shield size={20} />,
    children: [
      {
        id: 'users',
        title: 'User',
        icon: <User size={20} />,
        navLink: '/apps/users'
        /* children: [
          {
            id: 'list',
            title: 'List',
            icon: <Circle size={12} />,
            navLink: '/apps/user/list'
          },
          
        ] */
      },
      {
        id: 'roles',
        title: 'Roles',
        icon: <Users size={12} />,
        navLink: '/apps/roles'
      }
    ]
  },
  {
    id: 'accountSettings',
    title: 'Account Settings',
    icon: <Settings size={20} />,
    navLink: '/pages/parameters',
    permissions: ['admin']
  }
  /* {
    id: 'eCommerce',
    title: 'eCommerce',
    icon: <ShoppingCart size={20} />,
    children: [
      {
        id: 'shop',
        title: 'Shop',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/shop'
      },
      {
        id: 'detail',
        title: 'Details',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/product-detail'
      },
      {
        id: 'wishList',
        title: 'Wish List',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/wishlist'
      },
      {
        id: 'checkout',
        title: 'Checkout',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/checkout'
      }
    ]
  }, */
]
