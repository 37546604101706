// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appDissatisfactionType/getAllData', async () => {
  const response = await api.get('/dissatisfaction-type')

  return response.data.data
})

export const getData = createAsyncThunk('appDissatisfactionType/getData', async params => {
  const response = await api.get('/dissatisfaction-type', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getDissatisfactionType = createAsyncThunk('appDissatisfactionType/getDissatisfactionType', async id => {
  const response = await api.get(`/dissatisfaction-type/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appDissatisfactionType/getTicketCount', async id => {
  const response = await api.get(`/dissatisfaction-type/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appDissatisfactionType/getMessageCount', async id => {
  const response = await api.get(`/dissatisfaction-type/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appDissatisfactionType/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appDissatisfactionType/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appDissatisfactionType/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})


export const setError = createAsyncThunk('appDissatisfactionType/setError', async (data) => {
  return data
})

export const addDissatisfactionType = createAsyncThunk('appDissatisfactionType/addDissatisfactionType', async (dissatisfactionType, { dispatch, getState }) => {
  try {
    const response = await api.post('/dissatisfaction-type', dissatisfactionType)
    dispatch(getData(getState().dissatisfactionType.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateDissatisfactionType = createAsyncThunk('appDissatisfactionType/updateDissatisfactionType', async ({ id, dissatisfactionType }, { dispatch }) => {
  try {
    const response = await api.put(`/dissatisfaction-type/${id}`, dissatisfactionType)
    dispatch(getDissatisfactionType(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteDissatisfactionType = createAsyncThunk('appDissatisfactionType/deleteDissatisfactionType', async (id, { dispatch, getState }) => {
  await api.delete(`/dissatisfaction-type/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appDissatisfactionTypeSlice = createSlice({
  name: 'appDissatisfactionType',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDissatisfactionType: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const dissatisfactionType = state.data.find(u => u.id === id)

        if (dissatisfactionType) state.selectedDissatisfactionType = dissatisfactionType
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDissatisfactionType.fulfilled, (state, action) => {
        state.selectedDissatisfactionType = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedDissatisfactionType = {
          ...state.selectedDissatisfactionType, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedDissatisfactionType = {
          ...state.selectedDissatisfactionType, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addDissatisfactionType.pending, (state) => {
        state.saving = true
      })
      .addCase(addDissatisfactionType.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addDissatisfactionType.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateDissatisfactionType.pending, (state) => {
        state.saving = true
      })
      .addCase(updateDissatisfactionType.fulfilled, (state, action) => {
        state.saving = false
        state.selectedDissatisfactionType = action.payload.data
      })
      .addCase(updateDissatisfactionType.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appDissatisfactionTypeSlice.reducer
