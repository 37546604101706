import { useEffect } from 'react'

import { useDispatch } from "react-redux"

import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input"


// ** Reactstrap Imports
import {
    Row,
    Form,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    Spinner
} from 'reactstrap'

import { useForm, Controller } from 'react-hook-form'

import SelectSocialMedia from "../social-media/SelectSocialMedia"
import SelectDepartment from '../department/SelectDepartment'

import { getData as getAccounts } from '../social-media/store'
import { getData as getDepartments } from '../department/store'

export default function NewConversationModal({ open, toggleNewConversationModal }) {

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getAccounts({
            perPage: 50
        }))

        dispatch(getDepartments({
            perPage: 50
        }))
    }, [])


    const defaultValues = {
        message: ''
    }

    const {
        control,
        register
    } = useForm({
        defaultValues
    })

    function handlePhoneChange(newPhoneNumber) {
        console.log(newPhoneNumber)
    }


    return (
        <Modal isOpen={open} toggle={toggleNewConversationModal} className='modal-dialog-centered modal-lg'>
            <ModalHeader toggle={toggleNewConversationModal} >
                Nova Conversa
                </ModalHeader>

            <ModalBody>

                <Form>
                    <Row>
                        <Col md='12' sm='12' className='mb-1'>
                            <Label className='form-label' for='phone_number'>
                                <strong> Número do Contato </strong>
                            </Label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                defaultCountry="BR"
                                countryCallingCodeEditable={false}
                                international
                                name="phone_number"
                                onChange={handlePhoneChange}
                            />

                        </Col>
                        <Col md='12' sm='12' className='mb-1'>

                            <Label className='form-label' for='account_number'>
                                <strong> Conta de Saída </strong>
                            </Label>
                            <SelectSocialMedia name="account_number" control={control}></SelectSocialMedia>
                        </Col>
                        <Col md='12' sm='12' className='mb-1'>
                            <Label className='form-label' >
                                <strong> Departamento </strong>
                            </Label>
                            <SelectDepartment name='departament' control={control} ></SelectDepartment>
                        </Col>
                        <Col md='12' sm='12' className='mb-1'>
                            <Label className='form-label' for='message'>
                                <strong> Mensagem </strong>
                            </Label>
                            <Input type='textarea' bsSize='lg' control={control} {...register("message")} placeholder='Escreva sua mensagem' />
                        </Col>

                    </Row>
                </Form>

            </ModalBody>

            <ModalFooter >
                <Button type='button' color='secondary'  >
                    <strong onClick={toggleNewConversationModal}>Cancelar</strong>
                </Button>
                <Button type='submit' color='primary' >
                    <strong >Enviar</strong>
                </Button>
            </ModalFooter>
        </Modal >

    )
}