// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appClient/getAllData', async () => {
  const response = await api.get('/client')

  return response.data.data
})

export const getData = createAsyncThunk('appClient/getData', async params => {
  const response = await api.get('/client', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getClient = createAsyncThunk('appClient/getClient', async id => {
  const response = await api.get(`/client/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appClient/getTicketCount', async id => {
  const response = await api.get(`/client/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appClient/getMessageCount', async id => {
  const response = await api.get(`/client/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appClient/setSaving', async (saving) => {
  return saving
})

export const setError = createAsyncThunk('appClient/setError', async (data) => {
  return data
})

export const addClient = createAsyncThunk('appClient/addClient', async (client, { dispatch, getState }) => {
  try {
    const response = await api.post('/client', client)
    dispatch(getData(getState().client.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateClient = createAsyncThunk('appClient/updateClient', async ({ id, client }, { dispatch }) => {
  try {
    const response = await api.put(`/client/${id}`, client)
    dispatch(getClient(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteClient = createAsyncThunk('appClient/deleteClient', async (id, { dispatch, getState }) => {
  await api.delete(`/client/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appClientSlice = createSlice({
  name: 'appClient',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedClient: null,
    errors: {},
    saving: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.selectedClient = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedClient = {
          ...state.selectedClient, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedClient = {
          ...state.selectedClient, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addClient.pending, (state) => {
        state.saving = true
      })
      .addCase(addClient.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addClient.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateClient.pending, (state) => {
        state.saving = true
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.saving = false
        state.selectedClient = action.payload.data
      })
      .addCase(updateClient.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appClientSlice.reducer
