// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appTicketStatus/getAllData', async () => {
  const response = await api.get('/ticket-status')

  return response.data.data
})

export const getData = createAsyncThunk('appTicketStatus/getData', async params => {
  const response = await api.get('/ticket-status', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getTicketStatus = createAsyncThunk('appTicketStatus/getTicketStatus', async id => {
  const response = await api.get(`/ticket-status/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appTicketStatus/getTicketCount', async id => {
  const response = await api.get(`/ticket-status/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appTicketStatus/getMessageCount', async id => {
  const response = await api.get(`/ticket-status/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appTicketStatus/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appTicketStatus/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appTicketStatus/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appTicketStatus/setError', async (data) => {
  return data
})

export const addTicketStatus = createAsyncThunk('appTicketStatus/addTicketStatus', async (ticketStatus, { dispatch, getState }) => {
  try {
    const response = await api.post('/ticket-status', ticketStatus)
    dispatch(getData(getState().ticketStatus.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateTicketStatus = createAsyncThunk('appTicketStatus/updateTicketStatus', async ({ id, ticketStatus }, { dispatch }) => {
  try {
    const response = await api.put(`/ticket-status/${id}`, ticketStatus)
    dispatch(getTicketStatus(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteTicketStatus = createAsyncThunk('appTicketStatus/deleteTicketStatus', async (id, { dispatch, getState }) => {
  await api.delete(`/ticket-status/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appTicketStatusSlice = createSlice({
  name: 'appTicketStatus',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTicketStatus: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const ticketStatus = state.data.find(u => u.id === id)

        if (ticketStatus) state.selectedTicketStatus = ticketStatus
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTicketStatus.fulfilled, (state, action) => {
        state.selectedTicketStatus = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedTicketStatus = {
          ...state.selectedTicketStatus, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedTicketStatus = {
          ...state.selectedTicketStatus, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addTicketStatus.pending, (state) => {
        state.saving = true
      })
      .addCase(addTicketStatus.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addTicketStatus.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateTicketStatus.pending, (state) => {
        state.saving = true
      })
      .addCase(updateTicketStatus.fulfilled, (state, action) => {
        state.saving = false
        state.selectedTicketStatus = action.payload.data
      })
      .addCase(updateTicketStatus.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appTicketStatusSlice.reducer
