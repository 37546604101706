import axios from "axios"
import jwtDefaultConfig from "../../src/@core/auth/jwt/jwtDefaultConfig"
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import useJwt from '@src/auth/jwt/useJwt'

const jwtConfig = { ...jwtDefaultConfig }

const api = axios.create({
  baseURL: process.env.REACT_APP_API_V2_ROOT_URL
})

const logout = () => {
  useJwt.logout()
  localStorage.removeItem(jwtConfig.storageTokenKeyName)
  localStorage.removeItem("userData")
  window.location = "/login"
}

const handleTimeoutAlert = () => {
  let timerInterval
  Swal.fire({
    title: 'Login Expirado!',
    html: 'Faça login novamente para acessar o <b>Monitchat</b>.',
    timerProgressBar: true,
    timer: 3000,
    didOpen() {
      Swal.showLoading()
    },
    willClose() {
      clearInterval(timerInterval)
    }
  }).then(function (result) {
    logout()
  })
}

api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("accessToken"))
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    if (response && (response.status === 201)) {

      let message = 'Registro Incluído com sucesso!'

      if (response.data?.message) {
        message = response.data.message
      }


      toast.success(message, {
        duration: 5000
      })
    }

    if (response && (response.status === 204)) {
      toast.success('Registro excluído com sucesso!')
    }

    return response
  },
  (error) => {
    const { response } = error

    if (response && (response.status === 401 || response.status === 403)) {
      handleTimeoutAlert()
    }

    if (response && response.status === 404) {
      toast.error(`Url inválida`)
    }

    if (response && response.status === 422) {
      const errors = response.data.errors || []
      for (const key in errors) {
        const propErrors = errors[key]
        for (const err of propErrors) {
          toast.error(err)
        }
      }
    }

    if (response && response.status === 500) {
      toast.error(`${response.data.error}`)
    }

    return Promise.reject(error)
  }
)

export default api
