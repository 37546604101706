import { useSelector } from "react-redux"
import '../../../userBar.css'

//Icons
import classnames from 'classnames'
import InternalChatItem from './InternalChatItem'
import HeaderUserBar from './HeaderUserBar'

export default function UserBar() {
    const store = useSelector(state => ({ ...state.chat }))
    const { internal, selectInternalChatWindowOpen } = store

    return (
        <div className={classnames('content-user-bar', {
            show: selectInternalChatWindowOpen
        })} >
            <HeaderUserBar />
            {internal.map(chat => <InternalChatItem key={`chat-window-${chat.name}`} chat={chat} />)}
        </div>
    )
}
