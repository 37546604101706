// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appWebhook/getAllData', async () => {
  const response = await api.get('/webhook')

  return response.data.data
})

export const getData = createAsyncThunk('appWebhook/getData', async params => {
  const response = await api.get('/webhook', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getWebhook = createAsyncThunk('appWebhook/getWebhook', async id => {
  const response = await api.get(`/webhook/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appWebhook/getTicketCount', async id => {
  const response = await api.get(`/webhook/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appWebhook/getMessageCount', async id => {
  const response = await api.get(`/webhook/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appWebhook/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appWebhook/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appWebhook/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appWebhook/setError', async (data) => {
  return data
})

export const addWebhook = createAsyncThunk('appWebhook/addWebhook', async (webhook, { dispatch, getState }) => {
  try {
    const response = await api.post('/webhook', webhook)
    dispatch(getData(getState().webhook.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateWebhook = createAsyncThunk('appWebhook/updateWebhook', async ({ id, webhook }, { dispatch }) => {
  try {
    const response = await api.put(`/webhook/${id}`, webhook)
    dispatch(getWebhook(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteWebhook = createAsyncThunk('appWebhook/deleteWebhook', async (id, { dispatch, getState }) => {
  await api.delete(`/webhook/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appWebhookSlice = createSlice({
  name: 'appWebhook',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedWebhook: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const selectedWebhook = state.data.find(u => u.id === id)

        if (selectedWebhook) state.selectedWebhook = selectedWebhook
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getWebhook.fulfilled, (state, action) => {
        state.selectedWebhook = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedWebhook = {
          ...state.selectedWebhook, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedWebhook = {
          ...state.selectedWebhook, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addWebhook.pending, (state) => {
        state.saving = true
      })
      .addCase(addWebhook.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addWebhook.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateWebhook.pending, (state) => {
        state.saving = true
      })
      .addCase(updateWebhook.fulfilled, (state, action) => {
        state.saving = false
        state.selectedWebhook = action.payload.data
      })
      .addCase(updateWebhook.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appWebhookSlice.reducer
