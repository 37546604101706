// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appTicket/getAllData', async () => {
  const response = await api.get('/ticket')

  return response.data.data
})

export const getData = createAsyncThunk('appTicket/getData', async params => {
  const response = await api.get('/ticket', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getTicket = createAsyncThunk('appTicket/getTicket', async id => {
  const response = await api.get(`/ticket/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appTicket/getTicketCount', async id => {
  const response = await api.get(`/ticket/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appTicket/getMessageCount', async id => {
  const response = await api.get(`/ticket/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appTicket/setSaving', async (saving) => {
  return saving
})

export const setError = createAsyncThunk('appTicket/setError', async (data) => {
  return data
})

export const addTicket = createAsyncThunk('appTicket/addTicket', async (ticket, { dispatch, getState }) => {
  try {
    const response = await api.post('/ticket', ticket)
    dispatch(getData(getState().tickets.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateTicket = createAsyncThunk('appTickets/updateTicket', async ({ id, ticket }, { dispatch }) => {
  try {
    const response = await api.put(`/ticket/${id}`, ticket)
    dispatch(getTicket(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteTicket = createAsyncThunk('appTickets/deleteTicket', async (id, { dispatch, getState }) => {
  await api.delete(`/ticket/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appTicketsSlice = createSlice({
  name: 'appTickets',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTicket: null,
    errors: {},
    saving: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.selectedTicket = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedTicket = {
          ...state.selectedTicket, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedTicket = {
          ...state.selectedTicket, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addTicket.pending, (state) => {
        state.saving = true
      })
      .addCase(addTicket.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addTicket.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateTicket.pending, (state) => {
        state.saving = true
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.saving = false
        state.selectedTicket = action.payload.data
      })
      .addCase(updateTicket.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appTicketsSlice.reducer
