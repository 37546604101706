// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appContacts/getAllData', async () => {
  const response = await api.get('/contact')

  return response.data.data
})

export const getData = createAsyncThunk('appContacts/getData', async params => {
  const response = await api.get('/contact', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getContact = createAsyncThunk('appContacts/getContact', async id => {
  const response = await api.get(`/contact/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appContacts/getTicketCount', async id => {
  const response = await api.get(`/contact/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appContacts/getMessageCount', async id => {
  const response = await api.get(`/contact/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appContacts/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appContacts/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appContacts/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appContacts/setError', async (data) => {
  return data
})

export const addContact = createAsyncThunk('appContacts/addContact', async (contact, { dispatch, getState }) => {
  try {
    dispatch(setSaving(true))
    const response = await api.post('/contact', contact)
    dispatch(getData(getState().contacts.params))
    dispatch(setError({}))
    dispatch(toggleEditModal())
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    dispatch(setSaving(false))
    return err.response
  }
})

export const updateContact = createAsyncThunk('appContacts/updateContact', async ({ id, contact }, { dispatch, getState }) => {
  dispatch(setSaving(true))
  try {
    const response = await api.put(`/contact/${id}`, contact)
    dispatch(getData(getState().contacts.params))
    dispatch(setError({}))
    dispatch(toggleEditModal())
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    dispatch(setSaving(false))
    return err.response
  }
})

export const deleteContact = createAsyncThunk('appContacts/deleteContact', async (id, { dispatch, getState }) => {
  await api.delete(`/contact/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appContactsSlice = createSlice({
  name: 'appContacts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedContact: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const contact = state.data.find(u => u.id === id)

        if (contact) state.selectedContact = contact
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.selectedContact = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedContact = {
          ...state.selectedContact, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedContact = {
          ...state.selectedContact, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addContact.pending, (state) => {
        state.saving = true
      })
      .addCase(addContact.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addContact.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateContact.pending, (state) => {
        state.saving = true
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.saving = false
        state.selectedContact = action.payload.data
      })
      .addCase(updateContact.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appContactsSlice.reducer
