// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appPage/getAllData', async () => {
  const response = await api.get('/page')

  return response.data.data
})

export const getData = createAsyncThunk('appPage/getData', async params => {
  const response = await api.get('/page', { params })

  return {
    params,
    data: response.data.data,
    totalpages: response.data.total_pages
  }
})

export const getPage = createAsyncThunk('appPage/getPage', async id => {
  const response = await api.get(`/page/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appPage/getTicketCount', async id => {
  const response = await api.get(`/page/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appPage/getMessageCount', async id => {
  const response = await api.get(`/page/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appPage/setSaving', async (saving) => {
  return saving
})

export const setError = createAsyncThunk('appPage/setError', async (data) => {
  return data
})

export const addPage = createAsyncThunk('appPage/addPage', async (page, { dispatch, getState }) => {
  try {
    const response = await api.post('/page', page)
    dispatch(getData(getState().page.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updatePage = createAsyncThunk('appPage/updatePage', async ({ id, page }, { dispatch }) => {
  try {
    const response = await api.put(`/page/${id}`, page)
    dispatch(getPage(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deletePage = createAsyncThunk('appPage/deletePage', async (id, { dispatch, getState }) => {
  await api.delete(`/page/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appPageSlice = createSlice({
  name: 'appPage',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPage: null,
    errors: {},
    saving: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalpages
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.selectedPage = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedPage = {
          ...state.selectedPage, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedPage = {
          ...state.selectedPage, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addPage.pending, (state) => {
        state.saving = true
      })
      .addCase(addPage.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addPage.rejected, (state) => {
        state.saving = false
      })
      .addCase(updatePage.pending, (state) => {
        state.saving = true
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        state.saving = false
        state.selectedPage = action.payload.data
      })
      .addCase(updatePage.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appPageSlice.reducer
