// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appAlertWord/getAllData', async () => {
  const response = await api.get('/alert-word')

  return response.data.data
})

export const getData = createAsyncThunk('appAlertWord/getData', async params => {
  const response = await api.get('/alert-word', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getAlertWord = createAsyncThunk('appAlertWord/getAlertWord', async id => {
  const response = await api.get(`/alert-word/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appAlertWord/getTicketCount', async id => {
  const response = await api.get(`/alert-word/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appAlertWord/getMessageCount', async id => {
  const response = await api.get(`/alert-word/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appAlertWord/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appAlertWord/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appAlertWord/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appAlertWord/setError', async (data) => {
  return data
})

export const addAlertWord = createAsyncThunk('appAlertWord/addAlertWord', async (alertWord, { dispatch, getState }) => {
  try {

    const response = await api.post('/alert-word', alertWord)

    dispatch(getData(getState().alertWord.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    console.log(err, 'kkkkkkkkk')
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateAlertWord = createAsyncThunk('appAlertWord/updateAlertWord', async ({ id, alertWord }, { dispatch }) => {
  try {
    const response = await api.put(`/alert-word/${id}`, alertWord)
    dispatch(getAlertWord(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteAlertWord = createAsyncThunk('appAlertWord/deleteAlertWord', async (id, { dispatch, getState }) => {
  await api.delete(`/alert-word/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appAlertWordSlice = createSlice({
  name: 'appAlertWord',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAlertWord: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const alertWord = state.data.find(u => u.id === id)

        if (alertWord) state.selectedAlertWord = alertWord
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAlertWord.fulfilled, (state, action) => {
        state.selectedAlertWord = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedAlertWord = {
          ...state.selectedAlertWord, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedAlertWord = {
          ...state.selectedAlertWord, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addAlertWord.pending, (state) => {
        state.saving = true
      })
      .addCase(addAlertWord.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addAlertWord.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateAlertWord.pending, (state) => {
        state.saving = true
      })
      .addCase(updateAlertWord.fulfilled, (state, action) => {
        state.saving = false
        state.selectedAlertWord = action.payload.data
      })
      .addCase(updateAlertWord.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appAlertWordSlice.reducer
