// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appExtraInput/getAllData', async () => {
  const response = await api.get('/extra-input')

  return response.data.data
})

export const getData = createAsyncThunk('appExtraInput/getData', async params => {
  const response = await api.get('/extra-input', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getExtraInput = createAsyncThunk('appExtraInput/getExtraInput', async id => {
  const response = await api.get(`/extra-input/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appExtraInput/getTicketCount', async id => {
  const response = await api.get(`/extra-input/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appExtraInput/getMessageCount', async id => {
  const response = await api.get(`/extra-input/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appExtraInput/setSaving', async (saving) => {
  return saving
})

export const setError = createAsyncThunk('appExtraInput/setError', async (data) => {
  return data
})

export const addExtraInput = createAsyncThunk('appExtraInput/addExtraInput', async (extraInput, { dispatch, getState }) => {
  try {
    const response = await api.post('/extra-input', extraInput)
    dispatch(getData(getState().extraInput.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateExtraInput = createAsyncThunk('appExtraInput/updateExtraInput', async ({ id, extraInput }, { dispatch }) => {
  try {
    const response = await api.put(`/extra-input/${id}`, extraInput)
    dispatch(getExtraInput(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteExtraInput = createAsyncThunk('appExtraInput/deleteExtraInput', async (id, { dispatch, getState }) => {
  await api.delete(`/extra-input/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appExtraInputSlice = createSlice({
  name: 'appExtraInput',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedExtraInput: null,
    errors: {},
    saving: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getExtraInput.fulfilled, (state, action) => {
        state.selectedExtraInput = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedExtraInput = {
          ...state.selectedExtraInput, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedExtraInput = {
          ...state.selectedExtraInput, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addExtraInput.pending, (state) => {
        state.saving = true
      })
      .addCase(addExtraInput.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addExtraInput.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateExtraInput.pending, (state) => {
        state.saving = true
      })
      .addCase(updateExtraInput.fulfilled, (state, action) => {
        state.saving = false
        state.selectedExtraInput = action.payload.data
      })
      .addCase(updateExtraInput.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appExtraInputSlice.reducer
