
import {
    UncontrolledTooltip
} from 'reactstrap'


import { User } from 'react-feather'

export default function OnlineUsers() {
    return (

        <div  >
            < UncontrolledTooltip target='online-users' >
                Usuários Online
            </UncontrolledTooltip >
            <User id = 'online-users' size={22} />
            <span style={{position: 'relative', right: '5px'}}> 12 </span>
         
        </div >
    )
}