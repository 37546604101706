// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appDepartments/getAllData', async () => {
  const response = await api.get('/department')

  return response.data.data
})

export const getData = createAsyncThunk('appDepartments/getData', async params => {
  const response = await api.get('/department', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getDepartment = createAsyncThunk('appDepartments/getDepartment', async id => {
  const response = await api.get(`/department/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appDepartments/getTicketCount', async id => {
  const response = await api.get(`/department/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appDepartments/getMessageCount', async id => {
  const response = await api.get(`/department/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appDepartments/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appDepartments/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appDepartments/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})


export const setError = createAsyncThunk('appDepartments/setError', async (data) => {
  return data
})

export const addDepartment = createAsyncThunk('appDepartments/addDepartment', async (department, { dispatch, getState }) => {
  try {
    const response = await api.post('/department', department)
    dispatch(getData(getState().departments.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateDepartment = createAsyncThunk('appDepartments/updateDepartment', async ({ id, department }, { dispatch }) => {
  try {
    const response = await api.put(`/department/${id}`, department)
    dispatch(getDepartment(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteDepartment = createAsyncThunk('appDepartments/deleteDepartment', async (id, { dispatch, getState }) => {
  await api.delete(`/department/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appDepartmentsSlice = createSlice({
  name: 'appDepartments',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: {},
    selectedDepartment: {},
    errors: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const department = state.data.find(u => u.id === id)

        if (department) state.selectedDepartment = department
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.selectedDepartment = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedDepartment = {
          ...state.selectedDepartment, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedDepartment = {
          ...state.selectedDepartment, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addDepartment.pending, (state) => {
        state.saving = true
      })
      .addCase(addDepartment.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addDepartment.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateDepartment.pending, (state) => {
        state.saving = true
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.saving = false
        state.selectedDepartment = action.payload.data
      })
      .addCase(updateDepartment.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appDepartmentsSlice.reducer
