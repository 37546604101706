
// ** Reactstrap Imports
import {
    Row,
    Form,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    Label,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    Spinner
} from 'reactstrap'

import qrCode from '../../../../../assets/images/elements/qrCode.png'
import { FaWhatsapp, FaFacebook } from "react-icons/fa"


import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input"

export default function CreationUnofficialForm() {

    function handlePhoneChange(newPhoneNumber) {
        console.log(newPhoneNumber)
    }
    return (
        <div style={{ width: '100%' }}>
            <Form >

                <div className='mb-1'>

                    <Label>
                        Número do Telefone:

          </Label>

                    <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="BR"
                        countryCallingCodeEditable={false}
                        international
                        name="phone_number"
                        onChange={handlePhoneChange}
                    />

                </div>

                <div >

                    <div className='d-flex align-items-center flex-column mb-1'>

                        <img style={{ width: '210px' }} src={qrCode} />

                    </div>
                    <div className='d-flex align-items-center justify-content-center'>


                        <h2 style={{ color: '#000', margin: '2.5px', fontSize: '1.3em' }}>Autentificação WhatsApp</h2>
                        <FaWhatsapp size={16} />
                    </div>

                    <div style={{
                        background: '#f5f5f5',
                        borderRadius: '6px',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <ul className='mb-0'>
                            <li>
                                Aguarde o código aparecer na tela.
                            </li>
                            <li>
                                Aguarde vinte segundos para a conexão se estabelecer.
                            </li>
                            <li>
                                Leia o código com o Whatsapp no seu celular
                            </li>

                        </ul>


                    </div>
                </div >

                <div className=' d-flex flex-column align-items-center'>
                    <button style={{ padding: '7px', color: 'white', backgroundColor: '#ff4369', border: 'none', borderRadius: '6px', fontSize: '1.2em', marginTop: '2.5em' }}>
                        Conectar com a 360 Dialog
            </button>
                </div>

            </Form >
        </div>

    )

}