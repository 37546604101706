// ** Dropdowns Imports
// import IntlDropdown from './IntlDropdown'
// import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
import NavbarSearch from './NavbarSearch'
import NotificationDropdown from './NotificationDropdown'
import KnowledgeBase from './KnowledgeBase'
import InternalChatMenu from './InternalChatMenu'
import NewConversation from './NewConversation'
import Accounts from './Accounts'

// ** Third Party Components
import { Sun, Moon, UserPlus } from 'react-feather'

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap'


const NavbarUser = props => {
  // ** Props
  const { skin, setSkin, setInternalChatActive } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }

  return (
    <ul className='nav navbar-nav align-items-center ms-auto'>
      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <Accounts />
        </NavLink>
      </NavItem>

      <NavItem className='d-none d-lg-block'>
        <NavLink className=''>
          <NewConversation />
        </NavLink>
      </NavItem>

      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <InternalChatMenu setInternalChatActive={setInternalChatActive} />
        </NavLink>
      </NavItem>


      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <KnowledgeBase />
        </NavLink>
      </NavItem>


      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <ThemeToggler />
        </NavLink>
      </NavItem>
      <NavbarSearch />
      {/* <CartDropdown /> */}
      <NotificationDropdown />
      <UserDropdown />

    </ul>
  )
}
export default NavbarUser
