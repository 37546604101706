import React, { memo, useEffect, useState } from 'react'
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'


const SelectDepartment = ({
    control,
    isClearable = true,
    isMulti = false,
    name,
    rules = {}
}) => {

    const store = useSelector(state => state.departments)
    const [data, setData] = useState([])

    const setOptions = () => {
        setData(store.data.map(d => ({ value: d.id, label: d.name })))
    }

    useEffect(() => {
        setOptions()
    }, [store.data])

    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field }) => {
                return (
                    <Select
                        theme={selectThemeColors}
                        className='react-select'
                        classNamePrefix='select'
                        isMulti={isMulti}
                        placeholder='Pesquisar...'
                        {...field}
                        value={isMulti ? field.value.map(f => ({ value: f.value || f.id, label: f.label || f.name })) : data.filter(d => d.value === (field.value?.value || field.value))}
                        options={data}
                        isClearable={isClearable}
                    />
                )
            }}
        />
    )
}

export default memo(SelectDepartment)
