// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from "../../../../../src/utility/api"

export const getAllData = createAsyncThunk('appInterruptionType/getAllData', async () => {
  const response = await api.get('/interruption-type')

  return response.data.data
})

export const getData = createAsyncThunk('appInterruptionType/getData', async params => {
  const response = await api.get('/interruption-type', { params })

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total_pages
  }
})

export const getInterruptionType = createAsyncThunk('appInterruptionType/getInterruptionType', async id => {
  const response = await api.get(`/interruption-type/${id}`)
  return response.data
})

export const getTicketCount = createAsyncThunk('appInterruptionType/getTicketCount', async id => {
  const response = await api.get(`/interruption-type/${id}/ticket-count`)
  return response.data.total
})

export const getMessageCount = createAsyncThunk('appInterruptionType/getMessageCount', async id => {
  const response = await api.get(`/interruption-type/${id}/message-count`)
  return response.data.total
})

export const setSaving = createAsyncThunk('appInterruptionType/setSaving', async (saving) => {
  return saving
})

export const toggleEditModal = createAsyncThunk('appInterruptionType/toggleEditModal', async () => { })

export const onEditClick = createAsyncThunk('appInterruptionType/onEditClick', (id, { dispatch }) => {
  dispatch(toggleEditModal())
  return id
})

export const setError = createAsyncThunk('appInterruptionType/setError', async (data) => {
  return data
})

export const addInterruptionType = createAsyncThunk('appInterruptionType/addInterruptionType', async (interruptionType, { dispatch, getState }) => {
  try {
    const response = await api.post('/interruption-type', interruptionType)
    dispatch(getData(getState().interruptionType.params))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const updateInterruptionType = createAsyncThunk('appInterruptionType/updateInterruptionType', async ({ id, interruptionType }, { dispatch }) => {
  try {
    const response = await api.put(`/interruption-type/${id}`, interruptionType)
    dispatch(getInterruptionType(parseInt(id)))
    dispatch(setError({}))
    return response
  } catch (err) {
    dispatch(setError(err.response.data?.errors))
    return err.response
  }
})

export const deleteInterruptionType = createAsyncThunk('appInterruptionType/deleteInterruptionType', async (id, { dispatch, getState }) => {
  await api.delete(`/interruption-type/${id}`)
  await dispatch(getData(getState().users.params))
})

export const appInterruptionTypeSlice = createSlice({
  name: 'appInterruptionType',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedInterruptionType: {},
    errors: {},
    selectedUser: {},
    saving: false,
    editModalOpen: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(onEditClick.fulfilled, (state, action) => {
        const id = action.payload
        const interruptionType = state.data.find(u => u.id === id)

        if (interruptionType) state.selectedInterruptionType = interruptionType
      })
      .addCase(toggleEditModal.fulfilled, (state) => {
        state.editModalOpen = !state.editModalOpen
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getInterruptionType.fulfilled, (state, action) => {
        state.selectedInterruptionType = action.payload
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.selectedInterruptionType = {
          ...state.selectedInterruptionType, ticketsCount: action.payload
        }
      })
      .addCase(getMessageCount.fulfilled, (state, action) => {
        state.selectedInterruptionType = {
          ...state.selectedInterruptionType, sentMessagesCount: action.payload
        }
      })
      .addCase(setError.fulfilled, (state, action) => {
        state.errors = action.payload || {}
      })
      .addCase(setSaving.fulfilled, (state, action) => {
        state.saving = action.payload || false
      })
      .addCase(addInterruptionType.pending, (state) => {
        state.saving = true
      })
      .addCase(addInterruptionType.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(addInterruptionType.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateInterruptionType.pending, (state) => {
        state.saving = true
      })
      .addCase(updateInterruptionType.fulfilled, (state, action) => {
        state.saving = false
        state.selectedInterruptionType = action.payload.data
      })
      .addCase(updateInterruptionType.rejected, (state) => {
        state.saving = false
      })

  }
})

export default appInterruptionTypeSlice.reducer
